/**
 * String database
 *
 */

const STRINGS = {
  eventsEmptyText: [
    'It seems you are too early or its all over. There is no upcoming or active events in this property right now.',
    'Please Come back later.'
  ],
  roomTypeEmptyText: [
    'Ops, it looks like you are little early, we are preparing to host guests like you.',
    'Please Check back soon.'
  ],
  galleryImageGallery: [
    'Oops, No Images added by the property.'
  ],
  amenitiesList: [
    'Oops, No Amenities added by the property.'
  ],
  bookingConfirmationText: 'The following is not a confirmed booking, It will be confirmed post payment.',
  eventTicketBookingConfirmationText: 'The following is a provisional booking, it is temporarily on hold awaiting payment from user. If not paid in time booking will be cancelled.',
};

export default STRINGS;