/**
 * Room Type Actions
 *
 */

import AppAPI from '../../lib/api';

export const setRoomType = (roomTypeData, propertyId = null) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof roomTypeData === 'object') {
    dispatch({
      type: 'SET_ROOM_TYPE',
      data: roomTypeData,
    });
    return resolve(roomTypeData);
  } else if (roomTypeData && propertyId) {
    return AppAPI.bengineapi.get(propertyId + '/room-type/'+ roomTypeData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_ROOM_TYPE',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }
  return reject();
});

/**
 * Remove Room Type
 */
export function unSetRoomType() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_ROOM_TYPE',
      data: null
    });

  });
}


/**
 * Update Ticket Booking
 */
export const updateRoomType = (roomTypeData) => setRoomType(roomTypeData);