/**
 * Footer
 */
import _ from 'lodash';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
// Components
import ClickToView from '../general/ClickToView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import PolicyLinks from '../booking-engine/PolicyLinks';
import SocialLinks from '../booking-engine/SocialLinks';
import BuildInformation from './model/BuildInformation';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class Footer extends Component {

  isUrl = (s) => {
    try {
      new URL(s);
      return true;
    } catch {
      return false;
    }
  };

  render = () => {
    const {property} = this.props;
    return (
      <div className="footer border-top">
        <div className={'container my-3'}>
          <div className={'row'}>
            {property.google_maps_url &&
             <div className={'col-sm-12 col-md-4'}>
               <iframe
                 title={'Google Maps'}
                 src={property.google_maps_url}
                 className={'w-100'}
                 height={200}
                 frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
               />
             </div>
            }
            <div className={'col-sm-12 col-md-4'}>
              <h5>{property.name}</h5>
              <p className={'text-muted small'}>{property.full_address}</p>
              {property.sales_email &&
               <p className={'text-muted small mb-0'}>
                 E-mail : <ClickToView buttonClass={'btn-link'} content={property.sales_email}/>
               </p>
              }
              {property.sales_phone &&
               <p className={'text-muted small'}>
                 Phone : <ClickToView buttonClass={'btn-link'} content={property.sales_phone}/>
               </p>
              }
              {property.booking_engine_content.bengine_social_links &&
               <SocialLinks socialLinks={property.booking_engine_content.bengine_social_links}/>
              }
            </div>

            <div className={'col-sm-12 col-md-4'}>
              {property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_footer_links) &&
               <div className={'footer-links'}>
                 <ul className="list-unstyled">
                   {property.booking_engine_content.bengine_footer_links.map((data, i) => (
                     <li className="mb-1" key={i}>
                       {this.isUrl(data.link) ?
                         <a className={'small text-muted'} href={data.link}>
                           <FontAwesomeIcon icon={faExternalLinkAlt} className={'mr-1'} size={'xs'}/> {data.title}
                         </a> :
                         <Link to={`/p/${data.link}`} className={'small text-muted'}>
                           <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/> {data.title}
                         </Link>
                       }
                     </li>
                   ))}
                 </ul>
               </div>
              }
            </div>
          </div>
        </div>
        <div className={'border-top'}>
          <div className="px-3 pt-3">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-lg-left text-center">
                {property.name &&
                 <p><span className="text-muted small">{property.name}</span></p>
                }
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                <PolicyLinks property={property}/>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 text-lg-right text-center" onClick={()=>this.setState({showBuildInformation: true})}>
                <p className={'text-muted small'}>
                  Powered by <a
                    href={'https://renzo.in'} rel='noopener noreferrer'
                    target={'_blank'}>Renzo</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <BuildInformation
          show={this.state.showBuildInformation}
          onHide={()=>this.setState({showBuildInformation: false})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      showBuildInformation: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
