/**
 * Event Reducer
 *
 */

// Set initial state
const initialState = {
  ticketBooking: {},
  event: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_EVENT':
    let event = [];
    if (action.data && typeof action.data === 'object') {
      event = action.data;
    }

    return {
      ...state,
      event,
    };

  case 'SET_TICKET_BOOKING':
    let ticketBooking = [];
    if (action.data && typeof action.data === 'object') {
      ticketBooking = action.data;
    }

    return {
      ...state,
      ticketBooking,
    };

  default:
    return state;
  }
};

export default reducer;