/**
 *
 * Property Home
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
// Consts and Libs
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {Link} from 'react-router-dom';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyNotFound extends Component {

  render = () => {
    const {property} = this.props;
    const {booking_engine_content} = this.props.property;
    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>Not Found : {property.name}</title>
          {property.short_description && <meta name="description" content={property.short_description}/>}
        </Helmet>
        <div
          className={'home-header'}
          style={booking_engine_content.bengine_cover_image && booking_engine_content.bengine_cover_image.cover_2x && {
            backgroundImage: `url(${booking_engine_content.bengine_cover_image.cover_2x})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <table className={'home-header-inner w-100 h-100'}>
            <tbody>
              <tr>
                <td className={'text-center text-light'}>
                  <h1 className={'mb-4'}>404 Not Found!</h1>
                  <h6>We could not find what you were looking for.</h6>

                  <Link to={`/p/${this.props.match.params.propertyId}/`} className={'mt-5 btn btn-success'} >
                    Back Home
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyNotFound);
