import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RoomTypeDetails from './RoomTypeDetails';
import RoomTypeList from './RoomTypeList';
import PropertyNotFound from '../property/PropertyNotFound';
import RoomAvailability from './RoomAvailability';
import roomTypeRequired from '../../components/decorators/roomTypeRequired';

const RoomRoute = () => (
  <div>
    <Switch>
      <Route path={'/p/:propertyId/room/'} exact component={RoomTypeList}/>
      <Route path={'/p/:propertyId/room/availability/'} exact component={RoomAvailability}/>
      <Route path={'/p/:propertyId/room/availability/:checkin/to/:checkout'} exact component={RoomAvailability}/>
      <Route path={'/p/:propertyId/room/:roomTypeId'} exact component={roomTypeRequired(RoomTypeDetails)}/>
      <Route exact component={PropertyNotFound}/>
    </Switch>
  </div>
);

export default RoomRoute;