/**
 *
 * Event Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {Img} from 'react-image';
import Moment from 'moment';
// Consts and Libs
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import TicketListing from './TicketListing';
// Actions
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faQuestion, faTicketAlt} from '@fortawesome/free-solid-svg-icons';
import EventDetailsBox from '../../components/booking-engine/EventDetailsBox';
import SupportInformation from '../../components/booking-engine/SupportInformation';
import AvailabilityLookupBoxEvent from '../../components/booking-engine/AvailabilityLookupBoxEvent';
import {Alerts} from '../../components/ui';
import AppAPI from '../../lib/api';
import EventTicketBooking from './EventTicketBooking';
import FAQList from '../../components/booking-engine/FAQList';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  event: state.event.event,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class EventDetails extends Component {
  static componentName = 'EventDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  lookup = (date, captcha) => {
    if (date && captcha) {
      this.setState({loadingInner: true, results: [], resultMsg: {status: 'One moment...'}}, () => {
        if (this.ticketLookup) {
          this.ticketLookup.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      });

      AppAPI.bengineapi.post(`${this.props.match.params.propertyId}/event/${this.props.match.params.eventUrl}/lookup/`, {
        date: Moment(date).format('YYYY-MM-DD'),
        recaptcha: captcha,
      })
        .then(res => {
          this.setState({
            resultMsg: {
              error: '',
              success: `Availability for ${AppUtil.formatDateTime(res.date, 'datef')}`,
            },
            loadingInner: false,
            loading: false,
            lookupResponse: res,
            resultDate: Moment(res.date),
            currentDate: date,
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, loadingInner: false, lookupResponse: []});
        });
    }
  };

  bookingRedirect = (booking) => {
    this.props.history.push(`/p/${this.props.match.params.propertyId}/event/${this.props.match.params.eventUrl}/booking/${booking.hash}/`);
  };

  render = () => {
    const propertyData = this.props.property;
    const {event, property} = this.props;
    const {loading, error, resultMsg, lookupResponse, loadingInner} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;

    const sideBar = (
      <div>
        <div className={'mb-3'}>
          <AvailabilityLookupBoxEvent
            event={event}
            action={this.lookup}
            selectedDate={this.state.currentDate}
          />
        </div>
        <div className={'mb-3'}>
          <EventDetailsBox event={event}/>
        </div>
        <div>
          <SupportInformation event={event} property={property}/>
        </div>
      </div>
    );

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>{event.name} | {propertyData.name}</title>
          {event.slider_description && <meta name="description" content={event.slider_description}/>}
        </Helmet>

        <div
          className={'event-header'}
          style={event.event_cover_image && event.event_cover_image.cover_2x && {
            backgroundImage: `url(${event.event_cover_image.cover_2x})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className={'event-header-inner w-100 h-100 d-flex '}>
            <div className={'text-light align-self-center flex-fill'}>
              <div className={'container'}>

                <h1>{event.name}</h1>
                <p>
                  <FontAwesomeIcon icon={faMapMarker} className={'mr-2'}/>
                  {event.full_address ? event.full_address : property.full_address}
                </p>
                <p>
                  {AppUtil.formatDateTime(event.event_start_date, 'date')}
                  {event.event_type === Constants.EVENT_TYPE.RECURRING && `- ${AppUtil.formatDateTime(event.event_end_date, 'date')}`} |
                  <span className={'ml-2 badge ' + (AppUtil.bookingStatusColor(event.event_status))}>
                    {event.event_status_display}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className={'container pt-4'}>
          <nav aria-label="breadcrumb mb-5">
            <ol className="breadcrumb small bg-light">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/`}>Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/event/`}>Event List</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {event.name}
              </li>
            </ol>
          </nav>

          <div className={'row'}>
            <div className={'col-lg-8 col-sm-12 mb-3'}>

              {event.slider_description &&
               <p className={'display-linebreak font-weight-light mb-5'}>{event.slider_description}</p>
              }

              {!_.isEmpty(event.slider_images) &&
               <div id="carouselExampleIndicators" className="carousel slide mb-5" data-ride="carousel">
                 <ol className="carousel-indicators">
                   {event.slider_images.map((data, i) => (
                     <li
                       data-target="#carouselExampleIndicators"
                       key={i}
                       data-slide-to={i}
                       className={i === 0 ? 'active' : ''}
                     />
                   ))}
                 </ol>
                 <div className="carousel-inner">
                   {event.slider_images.map((data, i) => (
                     <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                       <Img
                         className="d-block w-100"
                         src={data.image.slider_2x}
                         loader={<Loading heightMatch={false}/>}
                       />
                     </div>
                   ))}
                 </div>
                 <a
                   className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                   data-slide="prev">
                   <span className="carousel-control-prev-icon" aria-hidden="true"/>
                   <span className="sr-only">Previous</span>
                 </a>
                 <a
                   className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                   data-slide="next">
                   <span className="carousel-control-next-icon" aria-hidden="true"/>
                   <span className="sr-only">Next</span>
                 </a>
               </div>
              }

              <div className={'mb-3 d-block d-lg-none'}>
                {sideBar}
              </div>

              {event.description &&
               <p className={'display-linebreak font-weight-light'}>{event.description}</p>
              }

              <div ref={this.ticketLookup}>
                <div className={'spacer-10'}/>
                <hr/>
                <div className={'spacer-10'}/>
                <h6 className={'mb-3'}><FontAwesomeIcon icon={faTicketAlt} className={'gray-cl mr-1'}/> Tickets</h6>

                <div className={'mb-3'}>
                  <AvailabilityLookupBoxEvent
                    event={event}
                    action={this.lookup}
                    selectedDate={this.state.currentDate}
                  />
                </div>

                <Alerts
                  status={resultMsg.status}
                  success={resultMsg.success}
                  error={resultMsg.error}
                />

                <TicketListing
                  loading={loadingInner}
                  tickets={lookupResponse.results || event.tickets}
                  action={(data) => this.setState({showEventTicketBooking: true, selectedTicket: data})}
                />
              </div>

              {(event.google_maps_url) &&
               <div className={'mb-3'}>
                 <p>How to reach us</p>
                 <iframe
                   title={'Google Maps'}
                   src={event.google_maps_url}
                   className={'w-100'}
                   height={400}
                   frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                 />
               </div>
              }

              {(event.event_faq && !_.isEmpty(event.event_faq)) &&
               <div>
                 <div className={'spacer-10'}/>
                 <hr/>
                 <div className={'spacer-10'}/>
                 <h6 className={'mb-3'}><FontAwesomeIcon icon={faQuestion} className={'gray-cl mr-1'}/> Frequently Asked
                   Questions</h6>
                 <div className={'mb-3'}>
                   <FAQList FAQList={property.booking_engine_content.bengine_faq}/>
                 </div>
               </div>
              }

            </div>
            <div className={'col-sm-12 col-lg-4 d-none d-lg-block'}>
              {sideBar}
            </div>
          </div>
        </div>
        <EventTicketBooking
          event={event}
          property={property}
          date={this.state.resultDate}
          ticket={this.state.selectedTicket}
          show={this.state.showEventTicketBooking}
          onHide={() => this.setState({showEventTicketBooking: false})}
          onSuccess={this.bookingRedirect}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      date: Moment().startOf('day'),
      loading: false,
      error: null,
      lookupResponse: [],
      currentDate: null,
      resultDate: null,
      loadingInner: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };

    this.ticketLookup = React.createRef();
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
