/**
 * PageHeader
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class PageHeader extends Component {
  static componentName = 'PageHeader';

  static propTypes = {
    back: PropTypes.bool,
    title: PropTypes.string,
    subHeader: PropTypes.bool,
    history: PropTypes.object,
    backLink: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.object,
  };

  render = () => {
    const {title, description, back, history, subHeader, backLink, icon} = this.props;
    return (
      <div className={'page-header-wrapper'}>
        <div className="page-header">
          <div className={subHeader ? 'h5' : 'h1'}>
            {(back !== false && history && history.length > 1) &&
             <div
               role="presentation" onKeyPress={this.handleKeyPress} onClick={() => (history ? history.goBack() : null)}
               className={'btn btn-outline-dark mr-2 ' + (subHeader ? 'btn-sm' : 'mb-2 ')}>
               <FontAwesomeIcon icon={faArrowLeft} size={subHeader ? 'sm' : 'lg'}/></div>
            }

            {backLink &&
             <Link to={backLink} className={'btn btn-outline-dark mr-2 ' + (subHeader ? 'btn-sm' : 'mb-2 ')}>
               <FontAwesomeIcon icon={faArrowLeft} size={subHeader ? 'sm' : 'lg'}/>
             </Link>
            }

            {icon && icon}
            {title}
          </div>
          {description && <p className={subHeader ? 'mb-0 small' : ''}>{description}</p>}
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default PageHeader;
