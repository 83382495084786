import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PropertyHome from './PropertyHome';
import PropertyImageGallery from './PropertyImageGallery';
import PropertyNotFound from './PropertyNotFound';
import PropertyAmenities from './PropertyAmenities';
import PropertyPageDetails from './PropertyPageDetails';

const PropertyRoute = () => (
  <div>
    <Switch>
      <Route path="/p/:propertyId/" exact component={PropertyHome}/>
      <Route path="/p/:propertyId/gallery" exact component={PropertyImageGallery}/>
      <Route path="/p/:propertyId/amenities" exact component={PropertyAmenities}/>
      <Route path="/p/:propertyId/:pageUrl" exact component={PropertyPageDetails}/>
      <Route exact component={PropertyNotFound}/>
    </Switch>
  </div>
);

export default PropertyRoute;