/**
 * Social Links
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class SocialLinks extends Component {

  static propTypes = {
    socialLinks: PropTypes.string,
  };


  isUrl = (s) => {
    try {
      new URL(s);
      return true;
    } catch {
      return false;
    }
  };


  icon = (title) => {
    let icon;
    switch (title) {
    case 'FB':
      icon = faFacebook;
      break;
    case 'TW':
      icon = faTwitter;
      break;
    case 'IG':
      icon = faInstagram;
      break;
    case 'YT':
      icon = faYoutube;
      break;
    default:
      icon = faStar;
    }
    return icon;
  };

  render = () => {
    const {socialLinks} = this.props;
    return (
      <div className="social-links">
        {!_.isEmpty(socialLinks) &&
         <ul className="list-inline">
           <li className="list-inline-item small text-muted">
             Follow us on :
           </li>
           {socialLinks.map((data, i) => (
             <li className="list-inline-item" key={i}>
               <a href={data.link} rel='noopener noreferrer' target={'_blank'}>
                 <FontAwesomeIcon icon={this.icon(data.title)} className={'mr-1'}/>
               </a>
             </li>
           ))}
         </ul>
        }
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(SocialLinks);
