/**
 * Suppot Information
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import ClickToView from '../general/ClickToView';

/* Component ==================================================================== */
class SupportInformation extends Component {
  static componentName = 'SupportInformation';

  static propTypes = {
    property: PropTypes.object.isRequired,
    event: PropTypes.object,
    header: PropTypes.bool,
  };

  static defaultProps = {
    header: true,
  };


  render = () => {
    const {property, event, header} = this.props;

    let email_address, phone;

    if (property && property.sales_email) {
      email_address = property.sales_email;
    }

    if (property && property.sales_phone) {
      phone = property.sales_phone;
    }

    if (event && event.sales_email) {
      email_address = event.sales_email;
    }

    if (event && event.sales_phone) {
      phone = event.sales_phone;
    }

    if (phone || email_address) {
      return (
        <div>
          {header &&
           <React.Fragment>
             <h5 className="mb-1">Support</h5>
             <p className={'small text-muted'}>
               Any questions ? we are just a tap away.
             </p>
           </React.Fragment>
          }

          <div className={'border p-3 rounded'}>
            <div className={'row'}>
              {email_address &&
               <div className={'col-6'}>
                 <p className={'small text-muted mb-0'}>
                   <FontAwesomeIcon icon={faEnvelope} size={'sm'} className={'blue-cl mr-1'}/> Email
                 </p>
                 <p className={'mb-0'}><ClickToView content={email_address} hiddenText={'Click to See Email'} /></p>
               </div>
              }
              {phone &&
               <div className={'col-6'}>
                 <p className={'small text-muted mb-0'}>
                   <FontAwesomeIcon icon={faPhone} size={'sm'} className={'blue-cl mr-1'}/> Phone
                 </p>
                 <p className={'mb-0'}>{phone}</p>
               </div>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return <div/>;
    }
  };

}

/* Export Component ==================================================================== */
export default SupportInformation;
