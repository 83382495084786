import React from 'react';
import {Route, Switch} from 'react-router-dom';
//scenes
import Home from './containers/launch';
import Property from './containers/property';
import Booking from './containers/booking';
import Event from './containers/event';
import Room from './containers/room';
import NotFound from './containers/NotFound';
import PropertyNotFound from './containers/property/PropertyNotFound';
//Decorators
import ScrollToTop from './components/decorators/scrollToTop';
import PropertyRequired from './components/decorators/propertyRequired';
// Components
import EnvironmentWarning from './components/general/EnvironmentWarning';
import {Footer, Header} from './components/ui';

const navigator = () => (
  <div className={'root-container'}>
    <EnvironmentWarning/>
    <div className={'main-container '}>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/error" exact component={NotFound}/>
          <Route path="/p/:propertyId/" component={PropertyRequired(navigatorWithAuth)}/>
          <Route exact component={NotFound}/>
        </Switch>
      </ScrollToTop>
    </div>
  </div>
);


const navigatorWithAuth = () => (
  <div>
    <Header/>
    <div className={'main-container'}>
      <Switch>
        <Route path="/p/:propertyId/error" exact component={PropertyNotFound}/>
        <Route path="/p/:propertyId/booking/" component={Booking}/>
        <Route path="/p/:propertyId/event/" component={Event}/>
        <Route path="/p/:propertyId/room/" component={Room}/>
        <Route path="/p/:propertyId/" component={Property}/>
      </Switch>
    </div>
    <Footer/>
  </div>
);

export default navigator;