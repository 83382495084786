/**
 *
 * Order Listing
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import {Constants} from '../../constants';
import AppUtil from '../../lib/util';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation, faEye, faSpinner, faSync} from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';
import OrderItemDetailsView from './OrderItemDetailsView';

/* Component ==================================================================== */
class OrderListView extends Component {
  static componentName = 'OrderListView';
  static propTypes = {
    order: PropTypes.object.isRequired,
    refresh: PropTypes.func,
    refreshing: PropTypes.bool,
  };

  componentDidMount() {
    this.checkPaymentStatus();
  }

  checkPaymentStatus = () => {
    const {order} = this.props;
    const {attempt} = this.state;
    if (!_.isEmpty(order.payments) && attempt < 3) {
      order.payments.forEach((data) => {
        if (data.pgateway_status === Constants.FETCH_STATUS.INITIATED || data.pgateway_status === Constants.FETCH_STATUS.PROCESSING) {
          setTimeout(()=>{
            this.setState({attempt: (attempt + 1), showRefresh: true});
            this.props.refresh();
            this.checkPaymentStatus();
          }, 5000)
        }
      })
    }
  }

  render = () => {
    const {order, refreshing} = this.props;
    return (
      <React.Fragment>
        <div className={'list-group mb-3'}>
          <div className={'list-group-item'}>
            <ul className={'list-inline list-inline-formatted'}>
              <li className={'list-inline-item'}>
                <p className={'small text-muted mb-0'}>Order Reference {this.state.attempt}</p>
                <p>{order.ref_no}</p>
              </li>
              <li className={'list-inline-item'}>
                <p className={'small text-muted mb-0'}>Order Status</p>
                <span className={'badge ' + (AppUtil.paymentStatusColor(order.order_status))}>
                  {order.order_status_display}
                </span>
              </li>

              {parseFloat(order.total_pending) > 0 &&
              <li className={'list-inline-item'}>
                <p className={'small text-muted mb-0'}>Payment Pending</p>
                <p><strong>{order.currency} {order.total_pending}</strong></p>
              </li>
              }
              {parseFloat(order.total_refunded) > 0 &&
              <li className={'list-inline-item'}>
                <p className={'small text-muted mb-0'}>Payment Refunded</p>
                <p><strong>{order.currency} {order.total_refunded}</strong></p>
              </li>
              }
              <li className={'list-inline-item'}>
                <p className={'small text-muted mb-0'}>Total</p>
                <p><strong>{order.currency} {order.total}</strong></p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          {!_.isEmpty(order.order_items) ?
            <table className="table small border">
              <tbody>
                {order.order_items.map((data, i) => {
                  if (data.status) {
                    return (
                      <tr key={i}>
                        <td className={'w-10'}>
                          <p className={'mb-0 small text-right'}>
                            {data.quantity} <strong>x</strong>
                            <br/> {data.quantity_type_display}
                          </p>
                        </td>
                        <td className={'w-75'}>
                          <strong>{data.description}</strong>
                          {data.additional_description &&
                          <p className={'small mb-0 text-muted'}>{data.additional_description}</p>
                          }
                          <p className={'mb-0 small'}>
                            {(parseInt(data.sub_quantity) > 1 || parseInt(data.sub_quantity_type) !== 1) &&
                            <React.Fragment>
                              {data.sub_quantity} {data.sub_quantity_type_display} |
                            </React.Fragment>
                            }
                            Price: {order.currency} {data.price} | Tax: {order.currency} {data.tax}
                            {parseFloat(data.discount) > 0 &&
                            <React.Fragment>
                              | Discount: {order.currency} {data.discount} ({data.discount_percent}%)
                            </React.Fragment>
                            }
                          </p>
                          <span
                            className="a m-0 p-0"
                            onClick={() => this.setState({
                              order: order,
                              orderItem: data,
                              showOrderItemDetailsView: true,
                            })}>
                            <FontAwesomeIcon icon={faEye} size={'sm'}/> Details
                          </span>
                        </td>
                        <td className="w-25 text-right">
                          <p className={'mb-0'}><strong>{order.currency} {data.total}</strong></p>
                          {data.complimentary &&
                          <span className={'badge badge-info'}>
                             Comp
                          </span>
                          }
                        </td>
                      </tr>
                    );
                  }
                  return <React.Fragment/>;
                })
                }
                <tr>
                  <td className="text-left" colSpan={2}>Sub Total</td>
                  <td className="text-right">{order.currency} {order.sub_total}</td>
                </tr>
                {parseFloat(order.complimentary_total) > 0 &&
                <tr>
                  <td className="text-left" colSpan={2}>Complimentary</td>
                  <td className="text-right">{order.currency} {order.complimentary_total}</td>
                </tr>
                }
                <tr>
                  <td className="text-left" colSpan={2}>
                    Tax
                    {(!_.isEmpty(order.tax_expanded) && !_.isEmpty(order.tax_expanded.tax_data)) &&
                    <React.Fragment>
                      <span
                        className={'a small p-0 m-0 ml-2'}
                        onClick={() => this.setState({priceTaxBreakDown: !this.state.priceTaxBreakDown})}
                      >
                        (Tax Information)
                      </span>
                      <Collapse in={this.state.priceTaxBreakDown}>
                        <table className={'table table-sm text-secondary format-table border mt-2'}>
                          <thead>
                            <tr>
                              <th className={'small text-dark w-50'}>Tax Name</th>
                              <th className={'small text-dark'}>Pre Tax</th>
                              <th className={'text-dark small'}>Tax Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.tax_expanded.tax_data.map((data, i) => (
                              <tr key={i}>
                                <td className={'small'}>{data.name}</td>
                                <td className={'small'}>
                                  {data.pre_total ? `${order.currency} ${data.pre_total}` : '...'}
                                </td>
                                <td className={'small'}>{`${order.currency} ${data.value}`}</td>
                              </tr>
                            ))}
                            <tr>
                              <td/>
                              <td/>
                              <td className={'small'}>{`${order.currency} ${order.tax_total}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Collapse>
                    </React.Fragment>
                    }
                  </td>
                  <td className="text-right">{order.currency} {order.tax_total}</td>
                </tr>
                {(parseFloat(order.order_discount) > 0) &&
                <tr>
                  <td className="text-left" colSpan={2}>Discount</td>
                  <td className="text-right">{order.currency} {order.order_discount}</td>
                </tr>
                }
                {parseFloat(order.total_rounding) !== 0 &&
                <tr>
                  <td colSpan={2} className="text-left">
                    Rounding
                  </td>
                  <td className="text-right">{order.currency} {order.total_rounding}</td>
                </tr>
                }
                <tr>
                  <td className="text-left" colSpan={2}><strong>Total</strong></td>
                  <td className="text-right">
                    <strong>{order.currency} {order.total}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            :
            <div className="text-center border rounded p-4">
              <h5><FontAwesomeIcon icon={faExclamation} className={'mr-2'}/> No items</h5>
              <p className={'text-muted small mb-0'}>No items in this order</p>
            </div>
          }
        </div>
        <div>
          {!_.isEmpty(order.payments) ?
            <div>
              <ul className={'list-group'}>
                {order.payments.map((payment, i) => (
                  <li className={'list-group-item'} key={i}>
                    <ul className={'list-inline list-inline-formatted'}>
                      <li className={'list-inline-item'}>
                        <p className={'small text-muted mb-0'}>Payment Reference</p>
                        <p>{payment.ref_no}</p>
                      </li>
                      {payment.payment_id &&
                      <li className={'list-inline-item'}>
                        <p className={'small text-muted mb-0'}>Payment ID</p>
                        <p>{payment.payment_id}</p>
                      </li>
                      }
                      <li className={'list-inline-item'}>
                        <p className={'small text-muted mb-0'}>Payment Status</p>
                        <span className={'badge ' + (AppUtil.paymentStatusColor(payment.payment_status))}>
                          {payment.payment_status_display}
                        </span>
                      </li>
                      <li className={'list-inline-item'}>
                        <p className={'small text-muted mb-0'}>Total</p>
                        <p><strong>{payment.currency} {payment.total}</strong></p>
                      </li>
                      {(payment.pgateway_status !== Constants.FETCH_STATUS.NOT_REQUIRED) &&
                      <li className={'list-inline-item'}>
                        <p className={'small text-muted mb-0'}>Payment Link</p>
                        {payment.payment_link ?
                          <a className={'btn btn-success btn-block btn-sm mt-1 mb-0'} href={payment.payment_link}>
                            {payment.payment_status === 1 ? 'View Payment Receipt' : 'Make Payment'}
                          </a>
                          :
                          <div>
                            { !(payment.pgateway_status === Constants.FETCH_STATUS.INITIATED || payment.pgateway_status === Constants.FETCH_STATUS.PROCESSING) ?
                              <span className={'small text-muted'}>
                                <FontAwesomeIcon pulse icon={faSpinner} className={'mr-2'}/>
                                generating...
                              </span>
                              :
                              <span className={`badge ${AppUtil.fetchStatusColor(payment.pgateway_status)}`}>
                                {payment.pgateway_status_display}
                              </span>
                            }
                            {this.state.showRefresh &&
                              <button
                                onClick={() => refreshing ? null : this.props.refresh()}
                                className={'btn btn-outline-success btn-sm ml-5'}
                                disabled={payment.pgateway_status === Constants.FETCH_STATUS.FAILED}
                              >
                                <FontAwesomeIcon pulse={refreshing} icon={faSync} size={'sm'}/> Refresh
                              </button>
                            }
                          </div>
                        }
                      </li>
                      }
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            :
            <div className="text-center border rounded p-4">
              <h5><FontAwesomeIcon icon={faExclamation} className={'mr-2'}/> No Payments</h5>
              <p className={'text-muted small mb-0'}>No payments in this order</p>
            </div>
          }
        </div>
        <OrderItemDetailsView
          order={this.state.order}
          orderItem={this.state.orderItem}
          show={this.state.showOrderItemDetailsView}
          onHide={() => this.setState({showOrderItemDetailsView: false})}
        />
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      order: null,
      attempt: 0,
      orderItem: null,
      showOrderItemDetailsView: false,
    };
  }
}

export default OrderListView;
