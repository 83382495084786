import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import * as RoomTypeActions from '../../redux/roomtype/actions';

// Components
import Loading from '../../components/general/Loading';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  roomType: state.roomType.roomType
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setRoomType: RoomTypeActions.setRoomType,
};

export default function decorator(ComposedComponent) {
  class RoomTypeRequired extends Component {
    static contextTypes = {
      match: PropTypes.object,
    };

    componentDidMount () {
      const {roomType} = this.props;
      if (Object.keys(this.props.roomType).length === 0 || this.props.match.params.roomTypeId !== roomType.room_type_url) {
        this.props.setRoomType(this.props.match.params.roomTypeId, this.props.match.params.propertyId)
          .catch(() => {
            this.props.history.push('/p/' + this.props.match.params.propertyId + '/error');
          });
      } else {
        if (!this.props.match.params.propertyId){
          this.props.history.push('/not-found');
        }
      }
    }

    render () {
      const {roomType} = this.props;
      if (_.isEmpty(roomType) || this.props.match.params.roomTypeId !== roomType.room_type_url) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

  }

  return connect(mapStateToProps, mapDispatchToProps)(RoomTypeRequired);
}