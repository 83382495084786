/**
 * Property Reducer
 *
 */

// Set initial state
const initialState = {
  booking: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_BOOKING':
    let booking = [];
    if (action.data && typeof action.data === 'object') {
      booking = action.data;
    }

    return {
      ...state,
      booking,
    };

  default:
    return state;
  }
};

export default reducer;