/**
 * Click to show content.
 *  used to hide sensitive info from bots and crawlers.
 */
import React from 'react';
import PropTypes from 'prop-types';

class ClickToView extends React.PureComponent {
  static componentName = 'ClickToView';

  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hiddenText: PropTypes.string,
    buttonClass: PropTypes.string,
  };

  static defaultProps = {
    hiddenText: 'Click to Show',
    buttonClass: 'btn btn-link p-0 m-0 btn-sm',
  };

  toggleContent = () => {
    this.setState({showContent: !this.state.showContent});
  };

  render = () => {
    const {showContent} = this.state;
    const {content, hiddenText, buttonClass} = this.props;

    return (
      <React.Fragment>
        {showContent ? content : <span onClick={()=>this.toggleContent()} className={`${buttonClass} a`}>{hiddenText}</span>}
      </React.Fragment>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showContent: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ClickToView;
