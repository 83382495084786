/**
 * Policy Links
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class PolicyLinks extends Component {
  static componentName = 'PolicyLinks';

  static propTypes = {
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  };

  render = () => {
    const {property} = this.props;
    return (
      <div className={'policy-link'}>
        {property.global_content && !_.isEmpty(property.global_content.policy_links) &&
         <ul className="list-inline mb-1">
           {property.global_content.policy_links.map((data, i) => (
             <li className="list-inline-item" key={i}>
               {data.link &&
                <a className={'small text-muted'} href={data.link} rel='noopener noreferrer' target={'_blank'}>
                  <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/>
                  {data.title}
                </a>
               }
             </li>
           ))}
         </ul>
        }
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default PolicyLinks;