/**
 * FAQ List
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import Collapse from 'react-bootstrap/Collapse';
// Consts and Libs
import AppUtil from '../../lib/util';
import {faChevronCircleDown, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class FAQList extends Component {

  static propTypes = {
    FAQList: PropTypes.object,
  };

  onClick = (data) => {
    const openList = AppUtil.insertOrRemoveArray(this.state.openList, data);
    this.setState({openList});
  };

  render = () => {
    const {FAQList} = this.props;
    const {openList} = this.state;
    return (
      <div className="social-links">
        {!_.isEmpty(FAQList) &&
         <div className='row'>
           {FAQList.map((data, i) => (
             <div className="col-12 col-md-6 mb-3" key={i}>
               <div className={'border p-3 rounded'}>
                 <div className={'a'} onClick={() => this.onClick(i + 1)}>
                   <div className={'float-right'}>
                     <FontAwesomeIcon
                       icon={openList.includes((i + 1)) ? faMinusCircle : faChevronCircleDown}
                       className={'mr-2'}
                     />
                   </div>
                   <p className={'mb-1'}><strong>Q. {data.title}</strong></p>
                 </div>
                 <Collapse in={openList.includes((i + 1))}>
                   <p className={'text-muted small mb-0'}>{data.description}</p>
                 </Collapse>
               </div>
             </div>
           ))}
         </div>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      openList: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(FAQList);
