/**
 * Availability Lookup Box Event
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
// Consts and Libs
import AppUtil from '../../lib/util';
import {AppConfig, Constants} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import Reaptcha from 'reaptcha';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
/* Component ==================================================================== */
class AvailabilityLookupBoxEvent extends Component {
  static componentName = 'AvailabilityLookupBoxEvent';

  static propTypes = {
    action: PropTypes.func,
    selectedDate: PropTypes.string,
    event: PropTypes.array.isRequired,
  };

  onSelect = (date) => {
    this.setState({date: Moment(date), lookupDisabled: false, showDatePicker: false});
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.selectedDate !== nextProps.selectedDate && nextProps.selectedDate) {
      this.setState({
        date: Moment(nextProps.selectedDate),
        lookupDisabled: true,
      });
    }
    return true;
  }


  action = (captcha) => {
    const {date} = this.state;
    if (this.props.action && !this.state.lookupDisabled) {
      this.setState({lookupDisabled: true, showDatePicker: false});
      this.props.action(date, captcha);
    }
    this.captcha.reset();
  };

  processAction = () => {
    if (this.state.captchaLoad) {
      this.captcha.execute();
    }
  };


  render = () => {
    const {event} = this.props;
    const {showDatePicker, lookupDisabled, captchaLoad} = this.state;

    let minDate = Moment().startOf('day'),
      date = this.state.date,
      maxDate = null;
    if (event) {
      if (Moment(event.event_start_date) > Moment().startOf('day')) {
        minDate = Moment(event.event_start_date);
      }
      maxDate = Moment(event.event_end_date);

      if (event.event_type === Constants.EVENT_TYPE.SINGLE) {
        date = Moment(event.event_start_date);
      } else {
        if (Moment(event.event_start_date) > Moment().startOf('day')) {
          date = Moment(event.event_start_date);
        }
      }
    }


    if (event) {
      return (
        <div className={'ticket-box'}>
          <div className={'border p-3 rounded'}>
            <div className={'row'}>
              <div className={'col-6'}>
                {event.event_type === Constants.EVENT_TYPE.RECURRING && event.event_status !== Constants.EVENT_STATUS.COMPLETED ?
                  <React.Fragment>
                    <div className={'a'} onClick={() => this.setState({showDatePicker: true})}>
                      <p className={'small text-muted mb-0'}>
                        <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Date
                      </p>
                      <p className={'mb-0'}>{AppUtil.formatDateTime(date, 'datef')}</p>
                    </div>
                    <div
                      className={(showDatePicker ? 'collapse.show' : 'collapse') + ' position-absolute calender-20 border rounded'}
                      id="datePicker"
                    >
                      <div className="bg-white">
                        <div className="text-left mt-2">
                          <Calendar
                            className={'mx-auto w-100'}
                            minDate={minDate.toDate()}
                            maxDate={maxDate ? maxDate.toDate() : null}
                            value={date.toDate()}
                            selectRange={false}
                            onChange={this.onSelect}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div>
                      <p className={'small text-muted mb-0'}>
                        <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Date
                      </p>
                      <p className={'mb-0'}>{AppUtil.formatDateTime(date, 'datef')}</p>
                    </div>
                  </React.Fragment>
                }
              </div>
              <div className={'col-6'}>
                <button
                  className={'btn btn-block btn-primary'}
                  disabled={event.event_status === Constants.EVENT_STATUS.COMPLETED || lookupDisabled || !captchaLoad}
                  onClick={() => this.processAction()}
                >
                  {captchaLoad ? 'Get Availability' : 'Please Wait'}
                </button>
              </div>
            </div>
          </div>
          <Reaptcha
            sitekey={AppConfig.reCaptchaKeyInvisible}
            ref={e => (this.captcha = e)}
            onVerify={this.action}
            onLoad={() => this.setState({captchaLoad: true})}
            size={'invisible'}
          />
        </div>
      );
    } else {
      return <div/>;
    }
  };


  constructor(props) {
    super(props);

    this.state = {
      captchaLoad: false,
      showDatePicker: false,
      lookupDisabled: false,
      date: Moment().startOf('day'),
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityLookupBoxEvent;
