/**
 * Room Availability Lookup
 *  View for Room Availability Lookup
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';
import Moment from 'moment';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
// Components
import {Alerts} from '../../components/ui';
import AvailabilityLookupBox from '../../components/booking-engine/AvailabilityLookupBox';
import SupportInformation from '../../components/booking-engine/SupportInformation';
import RoomAvailabilityListView from './RoomAvailabilityListView';
import Loading from '../../components/general/Loading';
import RoomBooking from './RoomBooking';
import {faShoppingCart, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// Actions
import * as GeneralActions from '../../redux/general/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  captcha: state.general.captcha,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  unSetCaptcha: GeneralActions.unSetCaptcha,
};

/* Component ==================================================================== */
class RoomAvailability extends Component {

  componentDidMount = () => {
    if (this.props.match.params.checkin || this.props.match.params.checkout) {
      let checkin = Moment(this.props.match.params.checkin);
      let checkout = Moment(this.props.match.params.checkout);
      this.setState({
        currentCheckin: checkin,
        currentCheckout: checkout,
        loading: false
      });

      if (!_.isEmpty(this.props.captcha)) {
        this.lookup(checkin.format('YYYY-MM-DD'), checkout.format('YYYY-MM-DD'), this.props.captcha);
        // Unset Captcha
        this.props.unSetCaptcha();
      }
    } else {
      this.setState({loading: false});
    }
  };

  lookup = (checkin, checkout, captcha) => {
    if (checkin && checkout && captcha) {
      this.setState(
        {loadingInner: true, results: [], resultMsg: {status: 'One moment...', success: ''}},
        () => {
          if (this.availability) {
            this.availability.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        });
      AppAPI.bengineapi.post(`${this.props.match.params.propertyId}/booking/lookup/`, {
        checkin: Moment(checkin).format('YYYY-MM-DD'),
        checkout: Moment(checkout).format('YYYY-MM-DD'),
        recaptcha: captcha,
      })
        .then(res => {
          this.setState({
            resultMsg: {
              error: '',
              success: `Availability for ${AppUtil.formatDateTime(res.checkin, 'datef')} to ${AppUtil.formatDateTime(res.checkout, 'datef')}`,
            },
            cartItems: [],
            loadingInner: false,
            lookupResponse: res,
            currentCheckin: Moment(checkin),
            currentCheckout: Moment(checkout),
          });
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}, loadingInner: false, lookupResponse: []});
        });
    }
  };

  bookingRedirect = (booking) => {
    this.props.history.push(`/p/${this.props.match.params.propertyId}/booking/${booking.hash}/`);
  };

  addToCart = (roomTypePackage) => {
    const {cartItems, lookupResponse} = this.state;
    const {results} = lookupResponse;
    if (results && results.find(item => (item.room_type === roomTypePackage.room_type))) {
      const {availability, max_quantity, occupancy_data, name} = results[results.findIndex(item => (item.room_type === roomTypePackage.room_type))];

      // For quantity filter all items in cart with room type
      let roomTypeQuantity = cartItems.filter(item => (item.room_type === roomTypePackage.room_type)).reduce((total, product) => total + product.quantity, 0);

      if (parseInt(availability.available_rooms) > roomTypeQuantity && max_quantity > roomTypeQuantity) {
        if (!cartItems.find(item => (item.room_type_package === roomTypePackage.room_type_package))) {
          // These data sets are used in booking preview and fixing no of guests refer usage in RoomBooking.js
          cartItems.push({
            room_type: roomTypePackage.room_type,
            room_type_package: roomTypePackage.room_type_package,
            package_data: roomTypePackage,
            room_type_data: {
              name: name,
              room_type: roomTypePackage.room_type,
            },
            occupancy_data: occupancy_data,
            quantity: 1,
          });
        } else if (parseInt(availability.available_rooms) > roomTypeQuantity && !availability.single_select) {
          cartItems[cartItems.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))].quantity++;
        }
        this.setState({cartItems});
      }
    }
  };

  removeFromCart = (roomTypePackage) => {
    const {cartItems} = this.state;
    const item = cartItems[cartItems.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))];
    if (item && item.quantity > 1) {
      cartItems[cartItems.findIndex(item => (item.room_type_package === roomTypePackage.room_type_package))].quantity--;
      this.setState({cartItems});
    } else if (item && item.quantity === 1) {
      this.setState({
        cartItems: cartItems.filter(item => (item.room_type_package !== roomTypePackage.room_type_package)),
      });
    }
  };


  render = () => {
    const {property} = this.props;
    const {resultMsg, lookupResponse, loadingInner, cartItems, loading} = this.state;
    const totalRooms = cartItems.reduce((total, product) => total + product.quantity, 0);
    const room_types = lookupResponse.results || property.room_types;
    if (loading) {
      return <Loading/>;
    }
    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>Availability Lookup | {property.name}</title>
        </Helmet>

        <div className={'container pt-4'}>
          <nav aria-label="breadcrumb mb-3">
            <ol className="breadcrumb small bg-light">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/`}>Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/room/`}>Room</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Availability Lookup
              </li>
            </ol>
          </nav>

          <div className={'row'}>
            <div className={'col-md-8 sm-12 mb-5'}>
              <div className={'mb-3'} ref={this.availability}>
                <AvailabilityLookupBox
                  header={false} singleLine={true}
                  selectedCheckinDate={this.state.currentCheckin}
                  selectedCheckoutDate={this.state.currentCheckout}
                  action={this.lookup}
                />
              </div>

              <Alerts
                status={resultMsg.status}
                success={resultMsg.success}
                error={resultMsg.error}
              />

              {room_types.map((data, key) => (
                <RoomAvailabilityListView
                  data={data} key={key} loading={loadingInner}
                  addToCart={this.addToCart}
                  removeFromCart={this.removeFromCart}
                  cartItems={cartItems.filter(item => (item.room_type === data.room_type))}
                />
              ))}

            </div>
            <div className={'col-md-4 col-sm-12 mb-5'}>
              <div>
                <SupportInformation property={property}/>
              </div>
              <div className="sticky-top pt-5">
                {_.isEmpty(cartItems) ?
                  <AvailabilityLookupBox
                    selectedCheckinDate={this.state.currentCheckin}
                    selectedCheckoutDate={this.state.currentCheckout}
                    action={this.lookup}
                  />
                  :
                  <ul className={'list-group'}>
                    <li className={'list-group-item'}>
                      <p className={'mb-0 text-muted small'}>
                        <FontAwesomeIcon icon={faShoppingCart} className={'mr-2 blue-cl'}/> Itinerary
                      </p>
                    </li>
                    {cartItems.map((data, i) => (
                      <li className={'list-group-item d-inline-flex'} key={i}>
                        <p className={'mb-0'}>
                          <strong>{data.quantity}</strong>
                          <FontAwesomeIcon icon={faTimes} className={'mx-2'}/>
                        </p>
                        <p className={'mb-0'}>
                          {data.room_type_data.name}<br/>
                          <span className={'small text-muted'}>{data.package_data.name}</span>
                        </p>
                      </li>
                    ))}
                    <li className={'list-group-item'}>
                      <button
                        className={'btn btn-success btn-block '}
                        onClick={() => this.setState({showRoomBooking: true})}
                      >Book
                      </button>
                      <div className={'fixed-bottom w-100 white-bg p-3 border-top text-center d-md-none'}>
                        <button
                          className={'btn btn-success btn-lg btn-block'}
                          onClick={() => this.setState({showRoomBooking: true})}
                        >
                          Book {totalRooms} Room{totalRooms > 1 && 's'}
                        </button>
                      </div>
                    </li>
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
        <RoomBooking
          show={this.state.showRoomBooking}
          onHide={() => this.setState({showRoomBooking: false})}
          cartdata={this.state.cartItems}
          property={this.props.property}
          checkin={this.state.currentCheckin}
          checkout={this.state.currentCheckout}
          onSuccess={this.bookingRedirect}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      cartItems: [],
      cartRoomTypeCounts: {},
      cartRoomTypePackageCounts: {},
      lookupResponse: [],
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      currentCheckin: null,
      currentCheckout: null,
      showRoomBooking: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
    this.availability = React.createRef();
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomAvailability);

