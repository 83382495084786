/**
 * Property Reducer
 *
 */

// Set initial state
const initialState = {
  property: {},
  pageContent: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_PROPERTY':
    let property = [];
    if (action.data && typeof action.data === 'object') {
      property = action.data;
    }

    return {
      ...state,
      property
    };
  case 'SET_PAGE':
    let pageContent = [];
    if (action.data && typeof action.data === 'object') {
      pageContent = action.data;
    }

    return {
      ...state,
      pageContent
    };

  default:
    return state;
  }
};

export default reducer;