/**
 * Rule List
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import Collapse from 'react-bootstrap/Collapse';
// Consts and Libs
import AppUtil from '../../lib/util';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RuleList extends Component {

  static propTypes = {
    ruleList: PropTypes.object,
  };

  onClick = (data) => {
    const openList = AppUtil.insertOrRemoveArray(this.state.openList, (data + 1));
    this.setState({openList});
  };

  render = () => {
    const {ruleList} = this.props;
    const {openList} = this.state;
    return (
      <div className="social-links">
        {!_.isEmpty(ruleList) &&
         <ul className='list-group'>
           {ruleList.map((data, i) => (
             <div className="list-group-item" key={i}>
               <p className={'mb-0'}>
                 <strong>{data.title}</strong>
               </p>
               {data.description && <p onClick={() => this.onClick(i)} className={'mb-0 small'}>Details</p>}
               <Collapse in={openList.includes((i + 1))}>
                 <p className={'text-muted small mb-0'}>{data.description}</p>
               </Collapse>
             </div>
           ))}
         </ul>
        }
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      openList: [],
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RuleList);
