/**
 * Property Amenities
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import {Amenities} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Component ==================================================================== */
class AmenitiesList extends Component {
  static componentName = 'PropertyAmenities';

  static propTypes = {
    amenitiesList: PropTypes.string,
  };

  render = () => {
    const {amenitiesList} = this.props;

    const selectedAmenityList = amenitiesList.split(',');

    const amenitiesDataList = Amenities.AMENITY_LIST.filter(item => (selectedAmenityList.includes(String(item.id))));
    const amenitiesCategory = [...new Set(amenitiesDataList.map(x => x.category))];

    return (
      <div className={'row'}>
        {Amenities.CATEGORY.filter(item => (amenitiesCategory.includes(item.id))).map((amenityCategoryData, i) => (
          <div className={'col-12'} key={i}>
            <h5 className={''}>{amenityCategoryData.name}</h5>
            <div className={'row pl-3 mb-5'}>
              {amenitiesDataList.filter(item => (item.category === amenityCategoryData.id)).map((amenityData, j) => (
                <div className={'col-md-3 col-sm-6 col-12 p-2'} key={j}>
                  <FontAwesomeIcon icon={amenityData.icon} className={'mr-2 blue-cl'}/>
                  {amenityData.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default AmenitiesList;
