import React from 'react';
import {Route, Switch} from 'react-router-dom';

import EventList from './EventList';
import EventDetails from './EventDetails';
import EventTicketBookingDetails from './EventTicketBookingDetails';
import PropertyNotFound from '../property/PropertyNotFound';
// Decorator
import eventRequired from '../../components/decorators/eventRequired';


const eventRoute = () => (
  <div>
    <Switch>
      <Route path={'/p/:propertyId/event/'} exact component={EventList}/>
      <Route path={'/p/:propertyId/event/:eventUrl/'} exact component={eventRequired(EventDetails)}/>
      {/* Do not change the URL without updating backend, this URL pattens is used in backend for PGateway redirect */}
      <Route
        path={'/p/:propertyId/event/:eventUrl/booking/:hash/'} exact
        component={eventRequired(EventTicketBookingDetails)}
      />
      <Route exact component={PropertyNotFound}/>
    </Switch>
  </div>
);

export default eventRoute;