/**
 * EventListView
 *  View for Event Listing
 *
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Img} from 'react-image';
// Consts and Libs
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import PlaceHolderImage from '../../images/resource/place-holder-bg.svg';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class EventListView extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  render = () => {
    const {data} = this.props;
    return (
      <div
        role="presentation"
        className={this.props.action && 'a'}
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
      >
        <div className="card">
          {(data.event_cover_image && data.event_cover_image.cover_sm) ?
            <Img
              className="card-img-top"
              src={data.event_cover_image.cover_sm}
              loader={<Loading heightMatch={false}/>}
            />
            :
            <img alt={'Empty Cover'} className="card-img-top bg-dark"  src={PlaceHolderImage} />
          }
          <div className="card-body">
            <p className={'text-muted small mb-1'}>
              <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-2'}/>
              {AppUtil.formatDateTime(data.event_start_date, 'date')}
              {data.event_type === Constants.EVENT_TYPE.RECURRING && `- ${AppUtil.formatDateTime(data.event_end_date, 'date')}`}
            </p>
            <h6 className="card-title mb-0">{data.name}</h6>
            <ul className="list-inline mb-1">
              <li className="list-inline-item small">
                <span className={'badge ' + (AppUtil.bookingStatusColor(data.event_status))}>
                  {data.event_status_display}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(EventListView);

