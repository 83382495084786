/**
 *
 * Property Gallery
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {Img} from 'react-image';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import ListView from '../../components/general/ListView';
import PageHeader from '../../components/booking-engine/PageHeader';
import {faImages} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class PropertyImageGallery extends Component {
  static componentName = 'PropertyImageGallery';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
  };

  fetchData = (page = 1, callback) => {
    AppAPI.bengineapi.get(this.props.match.params.propertyId + '/gallery/?page=' + page)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        });
        if (res.results.length > 0) {
          if (res.next) {
            callback(res.results);
          } else {
            callback(res.results, {
              allLoaded: true, // the end of the list is reached
            });
          }
        } else {
          callback();
        }
      })
      .catch((err) => {
        callback(null, {
          allLoaded: true, // the end of the list is reached
        });
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  };

  emptyView = () => {
    return (
      <div className={'col-12 text-center'}>
        <div className={'w-50 mx-auto mb-5'}>
          <img alt={'Empty Events'} className="w-100" src={require('../../images/resource/cycling-bg.png')}/>
        </div>
        {
          Strings.galleryImageGallery.map((data, i) => <p key={i}>{data}</p>)
        }
      </div>
    );
  };

  renderRow = (data, key) => {
    return (
      <div className={'col-sm-12 col-md-6 mb-3'} key={key}>
        <Img
          className="d-block w-100"
          src={data.image.slider_2x}
          loader={<Loading heightMatch={false}/>}
        />
      </div>
    );
  };

  render = () => {
    const propertyData = this.props.property;
    const {loading, error, resultMsg} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;
    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>Gallery | {propertyData.name}</title>
          <meta name="description" content={propertyData.meta_description}/>
        </Helmet>
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <PageHeader
                title={'Gallery'}
                description={`Scroll down to explore ${propertyData.name}`}
                icon={<FontAwesomeIcon icon={faImages} className={'mr-2 grey-cl'}/>}
              />
              <div className={'row mb-5'}>
                <ListView
                  rowView={this.renderRow}
                  onFetch={this.fetchData}
                  firstLoader={true}
                  emptyView={this.emptyView}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PropertyImageGallery);
