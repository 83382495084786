/**
 * Event Actions
 *
 */

import AppAPI from '../../lib/api';

/**
 * Set Event
 */
export const setEvent = (eventData, propertyId = null) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof eventData === 'object') {
    dispatch({
      type: 'SET_EVENT',
      data: eventData,
    });
    return resolve(eventData);
  } else if (eventData && propertyId) {
    // Fetch Room Data
    return AppAPI.bengineapi.get(propertyId + '/event/' + eventData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_EVENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Event
 */
export function unsetEvent() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_TICKET_BOOKING',
      data: null,
    });

  });
}

/**
 * Update Event
 */
export const updateEvent = (bookingData) => setEvent(bookingData);


/**
 * Set Ticket Booking
 */
export const setTicketBooking = (bookingData, propertyId = null) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingData === 'object') {
    dispatch({
      type: 'SET_TICKET_BOOKING',
      data: bookingData,
    });
    return resolve(bookingData);
  } else if (bookingData && propertyId) {
    return AppAPI.bengineapi.get(`${propertyId}/ticket-booking/${bookingData}/`)
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_TICKET_BOOKING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Ticket booking
 */
export function unsetTicketBooking() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_TICKET_BOOKING',
      data: null,
    });

  });
}

/**
 * Update Ticket Booking
 */
export const updateTicketBooking = (bookingData) => setTicketBooking(bookingData);