/**
 * Booking Actions
 *
 */

import AppAPI from '../../lib/api';

export const setBooking = (bookingData, propertyId = null) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof bookingData === 'object') {
    dispatch({
      type: 'SET_BOOKING',
      data: bookingData,
    });
    return resolve(bookingData);
  } else if (bookingData && propertyId) {
    // Fetch Room Data
    return AppAPI.bengineapi.get(propertyId + '/booking/'+ bookingData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_BOOKING',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove booking
 */
export function unSetBooking() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_BOOKING',
      data: null
    });

  });
}
