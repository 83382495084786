/**
 * General Actions
 *
 */


/**
 * Remove captcha
 */
export function unSetCaptcha() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_CAPTCHA',
      data: null,
    });

  });
}

/**
 * Set captcha
 */
export const setCaptcha = (data) => (dispatch) => new Promise(async (resolve, reject) => {
  if (data) {
    dispatch({
      type: 'SET_CAPTCHA',
      data: data,
    });
    return resolve(data);
  }
  return reject();
});
