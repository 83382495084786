/**
 * RoomTypeListView
 *  View for Room Type Listing
 *
 */

import _ from 'lodash';
import {Img} from 'react-image';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PlaceHolderImage from '../../images/resource/place-holder-bg.svg';
import {faBaby, faChild, faShapes, faUser} from '@fortawesome/free-solid-svg-icons';
/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomTypeListView extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  render = () => {
    const {data} = this.props;
    return (
      <div
        role="presentation"
        className={this.props.action && 'a'}
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
      >
        <div className="card">
          {(!_.isEmpty(data.room_type_cover_image) && data.room_type_cover_image.cover_sm) ?
            <Img
              alt={'Empty Cover'} className="card-img-top bg-dark"
              src={data.room_type_cover_image.cover_sm}
              loader={<Loading heightMatch={false}/>}
            />
            :
            <img
              alt={'Empty Cover'} className="card-img-top bg-dark"
              src={PlaceHolderImage}
            />
          }
          <div className="card-body">
            <h6 className="card-title mb-1">{data.name}</h6>
            <ul className="list-inline mb-1">
              <li className="list-inline-item small">
                <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2 blue-cl'}/>
                {data.base_occupancy}
              </li>
              {data.child_occupancy !== 0 &&
               <li className="list-inline-item small">
                 <FontAwesomeIcon icon={faChild} size={'sm'} className={'mr-2 blue-cl'}/>
                 {data.child_occupancy}
               </li>
              }
              {data.infant_occupancy !== 0 &&
               <li className="list-inline-item small">
                 <FontAwesomeIcon icon={faBaby} className={'mr-2 blue-cl'}/>
                 {data.infant_occupancy}
               </li>
              }

              {data.room_shape_display &&
               <li className="list-inline-item small">
                 <FontAwesomeIcon icon={faShapes} size={'sm'} className={'mr-2 blue-cl'}/>
                 {data.room_shape_display}
               </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeListView);

