/**
 * Room Availability List view
 *  View for Room Type Listing
 *
 */

import _ from 'lodash';
import {Img} from 'react-image';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import Collapse from 'react-bootstrap/Collapse';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faBed,
  faChild,
  faDoorOpen,
  faInfoCircle,
  faRulerCombined,
  faSmoking,
  faSmokingBan,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import RoomTypePackageListing from './RoomTypePackageListing';
import AppUtil from '../../lib/util';
import PlaceHolderImage from '../../images/resource/place-holder-bg.svg';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = () => ({});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class RoomAvailabilityListView extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    action: PropTypes.func,
    addToCart: PropTypes.func,
    cartItems: PropTypes.array,
    removeFromCart: PropTypes.func,
  };

  action = (data) => {
    if (this.props.action) {
      this.props.action(data);
    }
  };

  render = () => {
    const {data, loading, cartItems} = this.props;
    const selected = cartItems.reduce((total, product) => total + product.quantity, 0);
    return (
      <div
        role="presentation"
        className={this.props.action && 'a'}
        onKeyPress={this.handleKeyPress}
        onClick={() => this.action(data)}
      >
        <div className={'mb-3 p-2 border rounded'}>
          <div className={'row'}>
            <div className={'col-4'}>
              <div className={'mb-2'}>
                {(data.room_type_cover_image && data.room_type_cover_image.cover_sm) ?
                  <Img
                    className="w-100"
                    src={data.room_type_cover_image.cover_sm}
                    loader={<Loading heightMatch={false}/>}
                  />
                  :
                  <img alt={'Empty Cover'} className={'bg-dark w-100'}  src={PlaceHolderImage} />
                }
              </div>
            </div>
            <div className={'col-8'}>
              <p className={'float-md-right text-right mb-0 badge badge-success'}>
                {selected > 0 && `Selected : ${selected}`}
              </p>
              <h5 className={'my-2'}>{data.name}</h5>
              <ul className="list-inline inline-list-spacing small mb-0">
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2 blue-cl'}/>
                  {data.base_occupancy}

                </li>
                {data.child_occupancy !== 0 &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faChild} size={'sm'} className={'mr-2 blue-cl'}/>
                   {data.child_occupancy}
                 </li>
                }
                {data.infant_occupancy !== 0 &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faBaby} className={'mr-2 blue-cl'}/>
                   {data.infant_occupancy}
                 </li>
                }

                {data.room_dimension &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faRulerCombined} className={'mr-2 blue-cl'}/>
                   {data.room_dimension}
                 </li>
                }

                {data.smoking_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon
                     icon={data.smoking_type_display.includes('Non') ? faSmokingBan : faSmoking}
                     className={'mr-2 blue-cl'}/> {data.smoking_type_display}
                 </li>
                }

                {data.bed_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faBed} className={'mr-2 blue-cl'}/> {data.bed_type_display}
                 </li>
                }

                {data.view_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faDoorOpen} className={'mr-2 blue-cl'}/> {data.view_type_display}
                 </li>
                }
              </ul>
              {data.short_description &&
               <p
                 className={'mb-0 a small'} onClick={() => this.setState({description: !this.state.description})}>
                 <u><FontAwesomeIcon icon={faInfoCircle} className={'gray-cl mr-1'}/> More Information</u>
               </p>
              }
              {data.availability &&
               <p
                 className={`mt-1 mb-0 small ${AppUtil.availabilityStatusColor(data.availability.availability_percentage)}`}>
                 {data.availability.available_rooms} rooms
                 available {data.availability.available_rooms > data.max_quantity && `( Max ${data.max_quantity} )`}
               </p>
              }
            </div>
          </div>

          <Collapse in={this.state.description}>
            <div className={'mt-2'}>
              {data.short_description && <p className={'small'}>{data.short_description}</p>}
            </div>
          </Collapse>
          {!_.isEmpty(data.packages) &&
           <div className={'mt-2'}>
             <h6>Packages</h6>
             <div className="list-group list-group-sm">
               {data.packages.map((data, i) => (
                 <RoomTypePackageListing
                   loading={loading}
                   data={data} key={i}
                   addToCart={this.props.addToCart}
                   removeFromCart={this.props.removeFromCart}
                   cartItems={cartItems.filter(item => (item.room_type_package === data.room_type_package))}
                 />
               ))}
             </div>
           </div>
          }

        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      description: false,
    };
  }
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomAvailabilityListView);

