import React from 'react';
import {Route, Switch} from 'react-router-dom';

import BookingDetails from './BookingDetails';
import NotFound from '../NotFound';


const PaymentsRoute = () => (
  <div>
    <Switch>
      {/* Do not change the URL without updating backend, this URL pattens is used in backend for PGateway redirect */}
      <Route path={'/p/:propertyId/booking/:hash/'} exact component={BookingDetails}/>
      <Route exact component={NotFound}/>
    </Switch>
  </div>
);

export default PaymentsRoute;