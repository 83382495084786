import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import * as PropertyActions from '../../redux/property/actions';

// Components
import Loading from '../../components/general/Loading';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setProperty: PropertyActions.setProperty,
};

export default function decorator(ComposedComponent) {
  class PropertyRequired extends Component {
    static contextTypes = {
      match: PropTypes.object,
    };

    componentDidMount () {
      if (Object.keys(this.props.property).length === 0 && this.props.match.params.propertyId) {
        this.props.setProperty(this.props.match.params.propertyId)
          .catch(() => {
            this.props.history.push('/error');
          });
      } else {
        if (!this.props.match.params.propertyId){
          this.props.history.push('/not-found');
        }
      }
    }

    render () {
      const {property} = this.props;
      if (_.isEmpty(property)) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

  }

  return connect(mapStateToProps, mapDispatchToProps)(PropertyRequired);
}