/**
 *
 * Property Home
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {Img} from 'react-image';
import Moment from 'moment';
// Consts and Libs
// Components
import PropertyNotFound from './PropertyNotFound';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {
  faArrowRight,
  faCampground,
  faChartLine,
  faClock,
  faFileInvoice,
  faHome,
  faPrayingHands,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EventListView from '../event/EventListView';
import RoomTypeListView from '../room/RoomTypeListView';
import AvailabilityLookupBox from '../../components/booking-engine/AvailabilityLookupBox';
// Actions
import * as GeneralActions from '../../redux/general/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setCaptcha: GeneralActions.setCaptcha,
};

/* Component ==================================================================== */
class PropertyHome extends Component {
  static componentName = 'PropertyHome';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  doLookUp = (checkin, checkout, captcha) => {
    this.props.setCaptcha(captcha)
      .then(() => this.props.history.push('/p/' + this.props.match.params.propertyId + '/room/availability/' + Moment(checkin).format('YYYY-MM-DD') + '/to/' + Moment(checkout).format('YYYY-MM-DD')),
      );
  };

  render = () => {
    const {property} = this.props;
    const {booking_engine_content} = this.props.property;
    const {loading, error, notFound} = this.state;
    if (loading) return <Loading/>;
    if (notFound) return <PropertyNotFound/>;
    if (error) return <Error full={true} text={error}/>;

    const secureBoxContent = [
      {
        title: 'Best Price Guaranteed',
        description: 'Book with us directly with no commissions and fee.',
        icon: faChartLine,
        iconColor: 'green-cl',
      },
      {
        title: 'Instant Confirmation',
        description: 'Lock in a great price for your upcoming stay and get instant confirmation!',
        icon: faClock,
        iconColor: 'green-cl',
      },
      {
        title: 'Our Promise',
        description: 'Happiness is guaranteed. If we fall short of your expectations, give us a shout.',
        icon: faPrayingHands,
        iconColor: 'green-cl',
      },
    ];

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>{property.name}</title>
          {property.meta_description && <meta name="description" content={property.meta_description}/>}
        </Helmet>

        <div
          className={'home-header'}
          style={booking_engine_content.bengine_cover_image && booking_engine_content.bengine_cover_image.cover_2x && {
            backgroundImage: `url(${booking_engine_content.bengine_cover_image.cover_2x})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className={'home-header-inner w-100 h-100 d-flex '}>
            <div className={'text-light align-self-center flex-fill text-center'}>
              <h1 className={'mb-4'}>{booking_engine_content.bengine_title}</h1>
              <h6>{booking_engine_content.bengine_sub_title}</h6>
            </div>
          </div>
        </div>
        {property.allow_room_booking_engine &&
         <div className={'container mt-5'}>
           <div className={'row'}>
             <div className={'col-12 col-md-8 col-lg-8 mx-auto'}>
               <AvailabilityLookupBox action={this.doLookUp} header={false} singleLine={true}/>
             </div>
           </div>
         </div>
        }
        <div className={'container mt-5'}>
          {booking_engine_content.bengine_description &&
           <div className={'row'}>
             <div className={'col-sm-12 col-md-8 mb-5'}>
               <h2 className={'mb-3'}>
                 <FontAwesomeIcon icon={faFileInvoice} className={'mr-2 grey-cl'}/>
                 {booking_engine_content.bengine_description_title}
               </h2>
               <p className={'display-linebreak font-weight-light'}>{booking_engine_content.bengine_description}</p>
             </div>
             <div className={'col-sm-12 col-md-4 mb-5'}>
               {!_.isEmpty(secureBoxContent) &&
                <div className="list-group mt-md-5">
                  {secureBoxContent.map((data, i) => (
                    <div className="list-group-item" key={i}>
                      <div className="media">
                        <FontAwesomeIcon
                          icon={data.icon}
                          size={'2x'}
                          className={`align-self-center mr-3 ${data.iconColor}`}
                        />
                        <div className="media-body">
                          <p className="mb-1"><strong>{data.title}</strong></p>
                          <p className="mb-0 small text-muted">{data.description}</p>
                        </div>
                      </div>
                    </div>
                  ))
                  }
                </div>
               }
             </div>
           </div>
          }

          {(!_.isEmpty(property.room_types) && property.allow_room_booking_engine) &&
           <div className={'row mb-5'}>
             <div className={'col-12'}>
               <div className="row">
                 <div className="col-md-8">
                   <h2><FontAwesomeIcon icon={faHome} className={'mr-2 grey-cl'}/> Our Rooms</h2>
                 </div>
                 <div className="d-lg-flex align-items-center justify-content-end col-md-4">
                   <Link to={`/p/${this.props.match.params.propertyId}/room`}>
                     See all room <FontAwesomeIcon icon={faArrowRight} size={'sm'} className={'ml-2 grey-cl'}/>
                   </Link>
                 </div>
               </div>
               <div>
                 <div className={'row'}>
                   {property.room_types.map((data, i) => (
                     <div className={'col-3 my-3'} key={i}>
                       <RoomTypeListView
                         data={data}
                         action={(data) => this.props.history.push(`/p/${this.props.match.params.propertyId}/room/${data.room_type_url}/`)}
                       />
                     </div>
                   ))}
                 </div>
               </div>
             </div>
           </div>
          }

          {!_.isEmpty(property.slider_images) &&
           <div className={'row'}>
             <div
               className={`mb-5 col-sm-12 col-md-7 ${!booking_engine_content.bengine_slider_description && 'mx-auto'}`}>
               <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                 <ol className="carousel-indicators">
                   {property.slider_images.map((data, i) => (
                     <li
                       data-target="#carouselExampleIndicators"
                       key={i}
                       data-slide-to={i}
                       className={i === 0 ? 'active' : ''}
                     />
                   ))}
                 </ol>
                 <div className="carousel-inner">
                   {property.slider_images.map((data, i) => (
                     <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                       <Img
                         className="d-block w-100"
                         src={data.image.slider_2x}
                         loader={<Loading heightMatch={false}/>}
                       />
                     </div>
                   ))}
                 </div>
                 <a
                   className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                   data-slide="prev">
                   <span className="carousel-control-prev-icon" aria-hidden="true"/>
                   <span className="sr-only">Previous</span>
                 </a>
                 <a
                   className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                   data-slide="next">
                   <span className="carousel-control-next-icon" aria-hidden="true"/>
                   <span className="sr-only">Next</span>
                 </a>
               </div>
             </div>

             {booking_engine_content.bengine_slider_description &&
              <div className={'mb-5 col-sm-12 col-md-5 align-self-center'}>
                <h2 className={'mb-3'}>
                  <FontAwesomeIcon icon={faFileInvoice} className={'mr-2 grey-cl'}/>
                  {booking_engine_content.bengine_slider_title}
                </h2>
                <p
                  className={'font-weight-light display-linebreak'}>{booking_engine_content.bengine_slider_description}</p>
              </div>
             }

           </div>
          }

          <hr/>

          {(!_.isEmpty(property.events) && property.allow_event_booking_engine) &&
           <div className={'row mt-5 mb-5'}>
             <div className={'col-12'}>
               <div className="row">
                 <div className="col-md-8">
                   <h2><FontAwesomeIcon icon={faCampground} className={'mr-2 grey-cl'}/> Upcoming Events</h2>
                 </div>
                 <div className="d-lg-flex align-items-center justify-content-end col-md-4">
                   <Link to={`/p/${this.props.match.params.propertyId}/event`}>
                     See all events <FontAwesomeIcon icon={faArrowRight} size={'sm'} className={'ml-2 grey-cl'}/>
                   </Link>
                 </div>
               </div>
               <div>
                 <div className={'row'}>
                   {property.events.map((data, i) => (
                     <div className={'col-3 my-3'} key={i}>
                       <EventListView
                         data={data}
                         action={(data) => this.props.history.push(`/p/${this.props.match.params.propertyId}/event/${data.event_url}/`)}
                       />
                     </div>
                   ))}
                 </div>
               </div>
             </div>
           </div>
          }
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      propertyData: {},
      checkin: Moment().startOf('day'),
      checkout: Moment().add(1, 'days'),
      notFound: false,
      showDatePicker: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PropertyHome);
