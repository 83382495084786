/**
 * Location Market box
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressCard, faMapSigns} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class LocationDetailsBox extends Component {
  static componentName = 'LocationDetailsBox';

  static propTypes = {
    property: PropTypes.object.isRequired,
    event: PropTypes.object,
    header: PropTypes.bool,
  };

  static defaultProps = {
    header: true,
  };


  render = () => {
    const {property, event, header} = this.props;

    let address, google_maps_url;

    if (property && property.full_address) {
      address = property.full_address;
    }

    if (event && event.full_address) {
      address = event.full_address;
    }


    if (property && property.google_maps_url) {
      google_maps_url = property.google_maps_url;
    }

    if (event && event.google_maps_url) {
      google_maps_url = event.google_maps_url;
    }

    if (address || google_maps_url) {
      return (
        <div>
          {header &&
           <React.Fragment>
             <h5 className="mb-1">Location</h5>
             <p className={'small text-muted'}>
               Directions do to get to us.
             </p>
           </React.Fragment>
          }
          <div className={'border p-3 rounded'}>
            <div className={'row'}>
              {google_maps_url &&
               <div className={'col-12 mb-2'}>
                 <p className={'small text-muted mb-1'}>
                   <FontAwesomeIcon icon={faMapSigns} size={'sm'} className={'blue-cl mr-1'}/> Map
                 </p>
                 <iframe
                   title={'Google Maps'}
                   src={google_maps_url}
                   className={'w-100'}
                   height={200}
                   frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                 />
               </div>
              }

              {address &&
               <div className={'col-12'}>
                 <p className={'small text-muted mb-1'}>
                   <FontAwesomeIcon icon={faAddressCard} size={'sm'} className={'blue-cl mr-1'}/> Address
                 </p>
                 <p className={'mb-0 small'}>{address}</p>
               </div>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return <div/>;
    }
  };

}

/* Export Component ==================================================================== */
export default LocationDetailsBox;
