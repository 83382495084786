/**
 * Global Util Functions
 *
 */
import moment from 'moment-timezone';
import React from 'react';

const Entities = require('html-entities').AllHtmlEntities;

const entities = new Entities();

const RADIAN = Math.PI / 180;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

function striptags(input) {
  return input.replace(/(<([^>]+)>)/ig, '');
}

const UTIL = {
  /**
   * Format duration
   * Formats django's default duration field
   */
  formatDuration: (duration) => {
    if (duration) {
      const duration_data = moment.duration(duration.replace(/\s/g, '.'));
      const humanized_data = [];
      if (duration_data.days()) {
        humanized_data.push(`${duration_data.days()} Day`);
      }

      humanized_data.push(`${duration_data.hours()} Hour and ${duration_data.minutes()} Mins`);
      return humanized_data.join(', ');
    }
    return '';
  },
  /**
   * Format Week Days
   */
  formatWeekDays: (weekDaysList, expanded = false) => {
    const daysList = [
      {key: 'Sun', value: 'Sunday'},
      {key: 'Mon', value: 'Monday'},
      {key: 'Tue', value: 'Tuesday'},
      {key: 'Wed', value: 'Wednesday'},
      {key: 'Thu', value: 'Thursday'},
      {key: 'Fri', value: 'Friday'},
      {key: 'Sat', value: 'Saturday'},
    ];
    const activeDays = [];
    if (weekDaysList) {
      daysList.forEach((data) => {
        if (weekDaysList.includes(data.key) || weekDaysList.includes(data.key.toLowerCase())) {
          if (expanded) {
            activeDays.push(data.value);
          } else {
            activeDays.push(data.key);
          }

        }
      });
    }
    return activeDays.join(', ');
  },
  /**
   * Dynamic Select inputs
   */
  numericSelector: (start = 0, end = 10, labelPostFix = '') => {
    let selectOptions = [];
    while (start <= end) {
      selectOptions.push({
        value: start,
        label: `${start} ${labelPostFix}`,
      });
      start += 1;
    }
    return selectOptions;
  },
  /**
   * Expand Phone number
   */
  processPhoneNumber: (phone) => {
    try {
      return phoneUtil.parse(phone) ? phoneUtil.parseAndKeepRawInput(phone) : null;
    } catch (e) {
      return false;
    }
  },
  /**
   *  Calculates % of 2 numbers
   * */
  calculatePercentage: (data1, data2) => {
    let discount = 0;
    if (parseInt(data1) > 0 && parseInt(data2) > 0) {
      discount = (data1 / data2) * 100;
    }
    return discount.toFixed(2);
  },
  /**
   *  Prints label in center of pie chart
   * */
  renderCustomizedLabel: ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent > 0) {
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  },
  /**
   * Sorts object array based on a key in object
   * @param rooms
   * @param key
   */
  sortArray: (rooms, key) => {
    let sortedArray = {};
    // eslint-disable-next-line array-callback-return
    rooms.map((data) => {
      let arrayKey = 'default';
      if (data[key]) {
        arrayKey = data[key];
      }
      if (sortedArray[arrayKey]) {
        sortedArray[arrayKey].push(data);
      } else {
        sortedArray[arrayKey] = [data];
      }
    });
    return sortedArray;
  },
  /**
   * Formats price to 2 decimal place
   * @param price
   * @returns {string}
   */
  formatPrice: price => {
    return parseFloat(price).toFixed(2);
  },
  /**
   * Calculates room total from room data
   * @param roomData
   * @returns {*}
   */
  calculateRoomTotalsV2: roomData => {
    let room_total = parseFloat(roomData.package.base_price_effective_total);
    // check if room guest is less than base occupancy
    if (
      roomData.no_of_guest < roomData.occupancy_data.base_occupancy &&
      roomData.occupancy_data.sell_below_min_occupancy
    ) {
      let diff_guest = roomData.occupancy_data.base_occupancy - roomData.no_of_guest;
      room_total =
        room_total -
        UTIL.calculateDiscountGuestPrice(roomData.occupancy_data, room_total) *
        diff_guest;
    }

    if (roomData.no_of_guest > roomData.occupancy_data.base_occupancy) {
      let diff_guest = roomData.no_of_guest - roomData.occupancy_data.base_occupancy;
      room_total =
        room_total +
        parseFloat(roomData.package.guest_price_effective_total) * diff_guest;
    }

    // Child Guest
    if (roomData.no_of_child > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.child_price_effective_total) *
        roomData.no_of_child;
    }

    // Infant guest
    if (roomData.no_of_infant > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.infant_price_effective_total) *
        roomData.no_of_infant;
    }

    roomData.total = parseFloat(room_total).toFixed(2);

    return roomData;
  },
  /**
   * Calculates room total from room data
   * @param roomData
   * @returns {*}
   */
  calculateRoomTotals: roomData => {
    let room_total = parseFloat(roomData.package.base_price_effective_total);
    // check if room guest is less than base occupancy
    if (
      roomData.guest < roomData.occupancy_data.base_occupancy &&
      roomData.occupancy_data.sell_below_min_occupancy
    ) {
      let diff_guest = roomData.occupancy_data.base_occupancy - roomData.guest;
      room_total =
        room_total -
        UTIL.calculateDiscountGuestPrice(roomData.occupancy_data, room_total) *
        diff_guest;
    }

    if (roomData.guest > roomData.occupancy_data.base_occupancy) {
      let diff_guest = roomData.guest - roomData.occupancy_data.base_occupancy;
      room_total =
        room_total +
        parseFloat(roomData.package.guest_price_effective_total) * diff_guest;
    }

    // Child Guest
    if (roomData.child > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.child_price_effective_total) *
        roomData.child;
    }

    // Infant guest
    if (roomData.infant > 0) {
      room_total =
        room_total +
        parseFloat(roomData.package.infant_price_effective_total) *
        roomData.infant;
    }

    roomData.total = parseFloat(room_total).toFixed(2);

    return roomData;
  },
  /**
   * Calculate Discounted Guest Price
   *
   * Calculates discounted price for guest below the min occupancy
   * for occupancy_data object
   *    {
   *      sell_below_min_occupancy : True or False,
   *      discount_per_guest: xx,
   *      discount_type: [1 = percent, any other fixed]
   *    }
   */
  calculateDiscountGuestPrice: (occupancy_data, base_price = 0) => {
    let price = 0;
    if (occupancy_data.sell_below_min_occupancy) {
      if (occupancy_data.discount_type === 1) {
        // percentage
        price =
          base_price - (base_price * occupancy_data.discount_per_guest) / 100;
      } else {
        price = base_price - occupancy_data.discount_per_guest;
      }
    }
    return price.toFixed(2);
  },
  availabilityStatusColor: status => {
    if (status > 75) {
      return 'green-cl';
    } else if (status > 50) {
      return 'blue-cl';
    } else if (status > 25) {
      return 'purple-dark-cl';
    } else if (status > 10) {
      return 'orange-cl';
    } else {
      return 'red-cl';
    }
  },
  occupancyStatusColor: status => {
    if (status > 75) {
      return 'table-success';
    } else if (status > 50) {
      return 'table-primary';
    } else if (status > 25) {
      return 'table-info';
    } else if (status > 10) {
      return 'table-warning';
    } else {
      return 'table-danger';
    }
  },
  insertOrRemoveArray: (dataSet = null, data = null) => {
    if (dataSet && data) {
      if (dataSet.includes(data)) {
        dataSet = dataSet.filter(function (item) {
          return item !== data;
        });
      } else {
        dataSet.push(data);
      }
      return dataSet;
    }
    return [];
  },
  /**
   * Calculate tax
   *
   * Calculates tax for given total, tax class object
   *   {
   *         value : {
   *             fixed: xx,
   *             dynamic: xx
   *        },
   *        description : xxxx,
   *        tax_classes: [ x, x ]
   *    }
   */
  calculateTax: (tax, price = 0, quantity = 0) => {
    let tax_value = 0.0;
    if (tax.value && quantity > 0) {
      if (tax.value.fixed) {
        tax_value += quantity * tax.value.fixed;
      }

      if (tax.value.dynamic) {
        tax_value += price * quantity * parseFloat(tax.value.dynamic);
      }
    }
    return tax_value;
  },
  /**
   * Transfer status
   */
  routeStatusColor: status => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-primary';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-warning';
    case 4:
      return 'badge-light';
    default:
      return 'badge-secondary';
    }
  },
  reviewStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-danger';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-secondary';
    default:
      return 'badge-secondary';
    }
  },
  /**
   * Order item status
   */
  orderItemStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-light';
    case 3:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  /**
   * Payment Status Color
   */
  transferStatusColor: (status) => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    case 2:
    case 3:
      return 'badge-warning';
    case 4:
      return 'badge-info';
    default:
      return 'badge-secondary';
    }
  },
  ticketBookingClaimColor: (status) => {
    switch (status) {
    case 5:
      return 'badge-warning';
    case 4:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  bookingStatusColor: (status) => {
    switch (status) {
    case 1:
      return 'badge-info';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-success';
    case 4:
      return 'badge-dark';
    default:
      return 'badge-secondary';
    }
  },
  paymentStatusColor: (status) => {
    switch (status) {
    case -1:
    case 0:
      return 'badge-danger';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-primary';
    case 4:
      return 'badge-info';
    case 5:
      return 'badge-info';
    default:
      return 'badge-secondary';
    }
  },
  fetchStatusColor: (status) => {
    switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-warning';
    case 3:
      return 'badge-primary';
    case 4:
      return 'badge-secondary';
    default:
      return 'badge-danger';
    }
  },
  settlementStatusColor: (status) => {
    switch (status) {
    case -1:
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-success';
    default:
      return 'badge-secondary';
    }
  },
  subscriptionStatusColor: (status) => {
    switch (status) {
    case 0:
    case 1:
      return 'badge-info';
    case 2:
    case 3:
      return 'badge-success';
    case 4:
      return 'badge-warning';
    case 5:
      return 'badge-secondary';
    default:
      return 'badge-danger';
    }
  },
  notificationStatusColor: (status) => {
    switch (status) {
    case -1:
      return 'badge-danger';
    case 0:
      return 'badge-primary';
    case 1:
      return 'badge-success';
    case 2:
      return 'badge-info';
    case 3:
      return 'badge-warning';
    default:
      return 'badge-warning';
    }
  },
  /**
   * Capitalize first letter
   */
  ucFirst: string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  /**
   * Days difference
   */
  diffDateTime: (obj1, obj2, format) => {
    const t1 = moment(obj1),
      t2 = moment(obj2);

    switch (format) {
    case 'seconds':
      return t2.diff(t1, 'seconds');
    case 'hours':
      return t2.diff(t1, 'hours');
    default:
      return t2.diff(t1, 'days');
    }
  },
  /**
   * Format time
   */
  formatDateTime: (obj, format, parseFormat = null) => {
    let dateTimeObject;
    if (parseFormat) {
      dateTimeObject = moment(obj, parseFormat);
    } else {
      dateTimeObject = moment(obj);
    }

    switch (format) {
    case 'time':
      return dateTimeObject.format('h:mm a');
    case 'timez':
      return dateTimeObject.format('h:mm a ZZ');
    case 'date':
      return dateTimeObject.format('Do MMM');
    case 'datef':
      return dateTimeObject.format('Do MMM YYYY');
    case 'short':
      return dateTimeObject.format('DD-MM-YYYY HH:mm');
    case '24':
      return dateTimeObject.format('Do MMM YYYY HH:mm');
    case 'day':
      return dateTimeObject.format('dddd');
    case 'dte':
      return dateTimeObject.format('D');
    case 'month':
      return dateTimeObject.format('MMM');
    case 'year':
      return dateTimeObject.format('YYYY');
    default:
      return dateTimeObject.format('Do MMM YY h:mm a');
    }
  },
  /**
   * Test if Obj is empty
   */
  objIsEmpty: (obj) => {
    if (typeof obj === 'object' && !(obj instanceof Array)) {
      if (Object.keys(obj).length === 0) return true;
    }
    return false;
  },
  /**
   * Convert Obj to Arr
   */
  objToArr: obj => Object.keys(obj).map(k => obj[k]),
  /**
   * Limit characters, placing a ... at the end
   */
  limitChars: (str, limit = 15) => {
    if (str.length > limit) return `${str.substr(0, limit).trim()} ...`;
    return str;
  },
  /**
   * Decode HTML Entites
   */
  htmlEntitiesDecode: str => entities.decode(str),
  /**
   * Convert all HTMLEntities when Array
   */
  convertHtmlEntitiesArray: (arr) => {
    const finalArr = arr;

    if (arr instanceof Array) {
      arr.forEach((item, key) => {
        if (item instanceof Array) {
          finalArr[key] = UTIL.convertHtmlEntitiesArray(item);
        } else if (typeof item === 'object') {
          finalArr[key] = UTIL.convertHtmlEntitiesObject(item);
        } else if (typeof item === 'string') {
          finalArr[key] = entities.decode(striptags(item));
        }
      });
    }
    return finalArr;
  },
  /**
   * Convert all HTMLEntities when Object
   */
  convertHtmlEntitiesObject: (obj) => {
    const finalObj = obj;

    if (typeof obj === 'object' && !(obj instanceof Array)) {
      Object.keys(obj).forEach((key) => {
        const item = obj[key];

        if (item instanceof Array) {
          finalObj[key] = UTIL.convertHtmlEntitiesArray(item);
        } else if (typeof item === 'object') {
          finalObj[key] = UTIL.convertHtmlEntitiesObject(item);
        } else if (typeof item === 'string') {
          finalObj[key] = entities.decode(striptags(item));
        }
      });
    }

    return finalObj;
  },
  /**
   * Strips all HTML tags
   */
  stripTags: str => striptags(str),
};

/* Export ==================================================================== */
export default UTIL;