/**
 * Event Ticket Booking form
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {Img} from 'react-image';
import Reaptcha from 'reaptcha';
import Moment from 'moment';
// Consts and Libs
import AppUtil from '../../lib/util';
import {Constants, SelectList, AppConfig} from '../../constants';
// Form
import * as Yup from 'yup';
import {Checkbox, Input, Select} from 'react-formik-ui';
// Components
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faFileContract,
  faIdBadge,
  faMapMarker,
  faTicketAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import DateFormatted from '../../components/general/DateFormatted';
import PolicyLinks from '../../components/booking-engine/PolicyLinks';
import AppAPI from '../../lib/api';
import {Alerts} from '../../components/ui';
import _ from 'lodash';
import RuleList from '../../components/booking-engine/RuleList';
import PlaceHolderImage from '../../images/resource/place-holder-bg.svg';

/* Component ==================================================================== */
class EventTicketBooking extends Component {
  static componentName = 'EventTicketBooking';

  static propTypes = {
    date: PropTypes.object,
    event: PropTypes.array,
    ticket: PropTypes.array,
    property: PropTypes.array,
  };

  addTicket = (formData) => {
    const {property, event, ticket} = this.props;

    if (formData) {
      this.setState({resultMsg: {status: 'One moment...'}, loading: true});
      AppAPI.bengineapi.post(`${property.property_slug}/event/${event.event_url}/ticket/create/`, {
        ticket_date: Moment(this.props.date).format('YYYY-MM-DD'),
        event_id: ticket.event_id,
        ticket_id: ticket.ticket_id,
        ticket_quantity: formData.quantity,
        ticket_guest: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: (formData.code + formData.phone),
        },
        recaptcha: formData.recaptcha
      })
        .then(res => {
          if (res.hash) {
            this.setState({loading: false, resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                if (this.props.onSuccess) {
                  this.props.onSuccess(res);
                }
              }, 500);
            });
          } else {
            this.setState({loading: false, resultMsg: {error: 'Booking Failed.'}});
          }
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {
    const {event, property, show, ticket, date} = this.props;
    const {resultMsg} = this.state;

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneErrorMsg = 'Please enter a valid contact number';

    const formValidation = Yup.object().shape({
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      last_name: Yup.string().min(1, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string().matches(phoneRegExp, phoneErrorMsg).min(8, 'to short').max(10, `${phoneErrorMsg} without appending 0`).required(phoneErrorMsg),
      code: Yup.string().required('Required'),
      quantity: Yup.number().required('Required'),
      recaptcha: Yup.string().required('Required'),
    });

    const initialValues = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      code: property ? property.phone_number_code : '',
      tands: false,
      recaptcha: '',
      quantity: 1,
    };

    if (!show) {
      return <div/>;
    }

    let ticket_quantity = 0;
    if (ticket.availability) {
      ticket_quantity = ticket.max_quantity;
      if (ticket.ticket_quantity_type === Constants.EVENT_TICKET_TYPE.LIMITED && parseInt(ticket.availability.available_tickets) < parseInt(ticket_quantity)) {
        ticket_quantity = ticket.availability.available_tickets;
      }
    }


    return (
      <Modal
        {...this.props}
        size="xl"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={'d-flex flex-column'}>
            <p className={'mb-0 '}><strong>{event.name} </strong></p>
            <p className={'mb-0 '}><small>{ticket.name} | Booking date
              : {AppUtil.formatDateTime(date, 'datef')} </small>
            </p>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(data)=>this.addTicket(data)}>
          {({values, errors, touched, setFieldValue, handleSubmit}) => (
            <React.Fragment>
              <Modal.Body>
                <form>
                  <div className={'row'}>
                    <div className={'col-md-4 col-sm-12 mb-3 d-none d-lg-block'}>
                      <div className={'event-cover mb-3'}>
                        {(event.event_cover_image && event.event_cover_image.cover_sm) ?
                          <Img
                            className="card-img-top"
                            src={event.event_cover_image.cover_sm}
                            loader={<Loading heightMatch={false}/>}
                          />
                          :
                          <img alt={'Empty Cover'} className="card-img-top bg-dark" src={PlaceHolderImage} />
                        }
                      </div>
                      <h5>{event.name}</h5>
                      <p className={'small text-muted'}>
                        <FontAwesomeIcon icon={faMapMarker} className={'mr-2'}/>
                        {event.full_address ? event.full_address : property.full_address}
                      </p>
                      <p className={'small'}><strong>Booking Date</strong></p>
                      <div className={'row'}>
                        <div className={'col d-inline-flex'}>
                          <DateFormatted date={date}/>
                        </div>
                      </div>
                      <div className={'border rounded p-2 mt-3'}>
                        <p className={'mb-1 green-cl'}>
                          <strong>Go Green</strong>
                        </p>
                        <p className={'small text-muted'}>
                          Your phone's your ticket, your tickets will be emailed to your email address.
                        </p>
                      </div>
                    </div>
                    <div className={'col-lg-8 col-sm-12 border-left'}>
                      <h6 className={'mb-0'}>
                        <FontAwesomeIcon icon={faTicketAlt} size={'sm'} className={'grey-cl mr-1'}/> Ticket
                      </h6>
                      <p className={'small text-muted'}>Select the required quantity of ticket(s).</p>
                      <div className="list-group">
                        <div className={'list-group-item'}>
                          <div className={'row'}>
                            <div className={'col-8 col-md-8 col-lg-9 mb-3'}>
                              <p className={'small mb-1 text-muted'}>Ticket</p>
                              <div className={'d-flex flex-row'}>
                                <DateFormatted date={date}/>
                                <div className={'ml-3 align-self-center'}>
                                  <p className={'mb-0'}>
                                    <strong>{ticket.name}</strong>
                                  </p>
                                  <p className={'small text-muted'}>{ticket.currency} {ticket.base_price_effective} /
                                    ticket</p>
                                </div>
                              </div>
                            </div>
                            <div className={'col-4 col-md-4 col-lg-3'}>
                              <p className={'small mb-1 text-muted'}>Quantity</p>
                              <div>
                                <Select
                                  required
                                  className={'form-control small'}
                                  name='quantity'
                                  placeholder='Quantity'
                                  options={AppUtil.numericSelector(1, ticket_quantity)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={'list-group-item'}>
                          <div className={'row'}>
                            <div className={'col-5'}>
                              <p className={'mb-0'}>
                                <strong>Total</strong>
                              </p>
                            </div>
                            <div className={'col-7 text-right'}>
                              <p className={'mb-0'}>
                                <strong>{ticket.currency} {values.quantity * parseFloat(ticket.base_price_effective)}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={'mb-0 mt-2 text-muted small'}>
                        Inclusive of taxes, breakdown available in next page.
                      </p>
                      <hr/>
                      {ticket.description &&
                       <div>
                         <p className={'text-muted font-weight-light small'}>
                           {ticket.description}
                         </p>
                       </div>
                      }
                      {ticket.alert_description &&
                       <div>
                         <div className="alert alert-warning" role="alert">
                           <p className={'mb-0 small text-uppercase'}><strong>Note </strong></p>
                           <p className={'mb-0 small'}>{ticket.alert_description}</p>
                         </div>
                       </div>
                      }
                      <div className={'row'}>
                        <div className={'col-sm-12'}>
                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faUser} size={'sm'} className={'grey-cl mr-1'}/> Name
                          </h6>
                          <p className={'small text-muted'}>Name of the primary contact for the booking.</p>
                          <div className="form-row mb-2">
                            <div className="col-6 form-group">
                              <Input
                                required
                                name='first_name'
                                label={'First Name'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <Input
                                required
                                name='last_name'
                                label={'Last Name'}
                                className={'form-control'}
                              />
                            </div>
                          </div>

                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faFileContract} size={'sm'} className={'grey-cl mr-1'}/> Contact
                          </h6>
                          <p className={'small text-muted'}>Where would you like to receive the ticket and be contacted if required ?</p>
                          <div className="form-row">
                            <div className="col-lg-6 col-md-6 form-group">
                              <Input
                                required
                                name='email'
                                type={'email'}
                                label={'E-mail'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 ">
                              <div className={'row'}>
                                <div className={'col-4 pr-0 form-group'}>
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name='code'
                                    label={'Country'}
                                    placeholder='Select an Option'
                                    options={SelectList.phoneCountryCode()}
                                  />
                                </div>
                                <div className={'col-8 pl-0 form-group'}>
                                  <Input
                                    required
                                    type={'tel'}
                                    name='phone'
                                    label={'Phone'}
                                    className={'form-control rounded-left-0'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-12 form-group">
                              <Checkbox
                                required
                                className={'mr-2'}
                                name='tands'
                                text='Terms And Conditions'
                              />
                              <p className={'small text-muted'}>
                                I agree to {property.name} Terms of Service & Privacy Policy ( listed below ) and
                                Renzo's <a rel='noopener noreferrer' target={'_blank'}
                                  href={'https://renzo.in/policies/'}>Terms of service & Privacy Policy</a>.
                              </p>
                              <PolicyLinks property={property}/>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-12 form-group">
                              <div className={'form-element input'}>
                                <span className={'label'}>Recaptcha Validation</span>
                                <Reaptcha
                                  sitekey={AppConfig.reCaptchaKey}
                                  onVerify={(data) => setFieldValue('recaptcha', data)}
                                  onExpire={() => setFieldValue('recaptcha', '')}
                                />
                                {errors.recaptcha
                                 && touched.recaptcha && (
                                  <span className={'error'}>{errors.recaptcha}</span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_event_booking_rules)) &&
                       <div>
                         <h6 className={'mb-3'}><FontAwesomeIcon icon={faIdBadge} className={'grey-cl mr-1'}/> Booking Rules </h6>
                         <RuleList ruleList={property.booking_engine_content.bengine_event_booking_rules}/>
                       </div>
                      }
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer className={'justify-content-center'}>
                <div className={'row w-100'}>
                  <div className={'col-12 col-md-12 col-lg-8'}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />
                  </div>
                  <div className={'col-12 col-md-12 col-lg-4'}>
                    <button onClick={handleSubmit} className={'btn btn-success btn-block btn-lg'}>
                      Place Order <FontAwesomeIcon className={'white-cl mr-2'} icon={faArrowRight}/>
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      total: 0.0,
    };
  }
}


/* Export Component ==================================================================== */
export default EventTicketBooking;
