/**
 * Environment Warning
 */
import React, { Component } from 'react';

import AppConfig from '../../constants/config';

/* Component ==================================================================== */
class EnvironmentWarning extends Component {
  static componentName = 'EnvironmentWarning';

  render = () => {
    return (
      <React.Fragment>
        {(AppConfig.environment !== 'production') &&
         <div className="header sticky-top mb-0 environment-warning">
           <div className="alert alert-warning alert-dismissible fade show mb-0" role="alert">
             <strong>Warning: </strong> This is a staging site, connected to end point : {AppConfig.endpoint}.
             The production site is <a href={AppConfig.dashboardHostname}>here</a>.
             <button type="button" className="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
         </div>
        }
      </React.Fragment>

    );
  };
}

/* Export Component ==================================================================== */
export default EnvironmentWarning;