/**
 *
 * Property Amenities
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Components
import PropertyNotFound from './PropertyNotFound';
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../components/booking-engine/PageHeader';
import AmenitiesList from '../../components/booking-engine/AmenitiesList';
import {faServicestack} from '@fortawesome/free-brands-svg-icons';
import {Strings} from '../../constants';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};

/* Component ==================================================================== */
class PropertyAmenities extends Component {
  static componentName = 'PropertyAmenities';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render = () => {
    const {property} = this.props;
    const {booking_engine_content} = this.props.property;

    const {loading, error, notFound} = this.state;
    if (loading) return <Loading/>;
    if (notFound) return <PropertyNotFound/>;
    if (error) return <Error full={true} text={error}/>;

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>Amenities | {property.name}</title>
          {property.meta_description && <meta name="description" content={property.meta_description}/>}
        </Helmet>


        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <PageHeader
                title={'Amenities'}
                description={`Service and facilities at ${property.name}`}
                icon={<FontAwesomeIcon icon={faServicestack} className={'mr-2 grey-cl'}/>}
              />
            </div>
          </div>
          {!_.isEmpty(booking_engine_content.bengine_amenities_list) ?
            <AmenitiesList amenitiesList={booking_engine_content.bengine_amenities_list}/>
            :
            <div className={'col-12 text-center'}>
              <div className={'w-50 mx-auto mb-5'}>
                <img alt={'Amenity List'} className="w-100" src={require('../../images/resource/cycling-bg.png')}/>
              </div>
              {
                Strings.amenitiesList.map((data, i) => <p key={i}>{data}</p>)
              }
            </div>
          }
        </div>


      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      propertyData: {},
      notFound: false,
      showDatePicker: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      results: [],
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PropertyAmenities);
