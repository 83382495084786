/**
 * Room Booking form
 */
import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Reaptcha from 'reaptcha';
import Moment from 'moment';
import {Img} from 'react-image';
// Consts and Libs
import AppUtil from '../../lib/util';
import AppAPI from '../../lib/api';
import {AppConfig, SelectList} from '../../constants';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Checkbox, Input, Select} from 'react-formik-ui';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faDoorOpen,
  faFileContract, faIdBadge,
  faMapMarker,
  faMinus,
  faPlus,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import PolicyLinks from '../../components/booking-engine/PolicyLinks';
import DateFormatted from '../../components/general/DateFormatted';
import Loading from '../../components/general/Loading';
import {Alerts} from '../../components/ui';
import RuleList from '../../components/booking-engine/RuleList';

/* Component ==================================================================== */
class RoomBooking extends Component {
  static componentName = 'RoomtBooking';

  static propTypes = {
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    cartdata: PropTypes.array,
    property: PropTypes.object,
  };

  addBooking = (formData) => {
    const {rooms} = this.state;
    const {property} = this.props;

    if (formData && !_.isEmpty(rooms)) {
      this.setState({resultMsg: {status: 'One moment...'}, loading: true});
      AppAPI.bengineapi.post(`${property.property_slug}/booking/create/`, {
        booking_room_data: rooms,
        guest: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: (formData.code + formData.phone),
        },
        recaptcha: formData.recaptcha
      })
        .then(res => {
          if (res.hash) {
            this.setState({loading: false, resultMsg: {success: 'Success'}}, () => {
              setTimeout(() => {
                if (this.props.onSuccess) {
                  this.props.onSuccess(res);
                }
              }, 500);
            });
          } else {
            this.setState({loading: false, resultMsg: {error: 'Booking Failed.'}});
          }
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({resultMsg: {error}});
        });
    }
  };

  fetchInitData = () => {
    const {cartdata, checkin, checkout} = this.props;
    const rooms = [];

    let total = parseFloat('0.0');
    if (cartdata) {

      /**
       * Cart data contains room type data and quantity of rooms
       * ####  Refer RoomAvailability.js addToCart   ####
       */
      // Init functions has to create a new entry for no of rooms required and pre-fill each room with
      // corresponding base quantity
      cartdata.forEach((data) => {
        for (let i = 0; i < data.quantity; i++) {
          let roomData = {
            no_of_guest: data.occupancy_data.base_occupancy,
            no_of_infant: 0,
            no_of_children: 0,
            package: data.package_data,
            occupancy_data: data.occupancy_data,
            package_name: data.package_data.name,
            room_type: data.room_type_data.room_type,
            room_type_name: data.room_type_data.name,
            checkin: Moment(checkin).format('YYYY-MM-DD'),
            checkout: Moment(checkout).format('YYYY-MM-DD'),
            room_type_packages: [
              {
                room_type_package: data.package_data.room_type_package,
                start: Moment(data.checkin).format('YYYY-MM-DD'),
                end: Moment(data.checkout).format('YYYY-MM-DD'),
              },
            ],
          };
          roomData = AppUtil.calculateRoomTotalsV2(roomData);
          rooms.push(roomData);
        }
      });
    }

    this.setState({
      loading: false,
      rooms: rooms,
      base_total: total,
      total: total,
    });
  };

  updateIndex = (i, j, k) => {
    if (j != null && k) {
      const {rooms} = this.state;

      // eslint-disable-next-line
      switch (k) {
      case 'g+':
        rooms[j].no_of_guest =
          rooms[j].no_of_guest < rooms[j].occupancy_data.max_occupancy
            ? rooms[j].no_of_guest + 1
            : rooms[j].no_of_guest;
        break;
      case 'g-':
        rooms[j].no_of_guest = rooms[j].no_of_guest > 1 ? rooms[j].no_of_guest - 1 : 1;
        break;
      case 'c+':
        if (rooms[j].no_of_children < rooms[j].occupancy_data.child_occupancy) {
          rooms[j].no_of_children = rooms[j].no_of_children + 1;
        }
        break;
      case 'c-':
        if (rooms[j].no_of_children > 0) {
          rooms[j].no_of_children = rooms[j].no_of_children - 1;
        }
        break;
      case 'i+':
        if (rooms[j].no_of_infant < rooms[j].occupancy_data.infant_occupancy) {
          rooms[j].no_of_infant = rooms[j].no_of_infant + 1;
        }
        break;
      case 'i-':
        if (rooms[j].no_of_infant > 0) {
          rooms[j].no_of_infant = rooms[j].no_of_infant - 1;
        }
        break;
      }

      rooms[j] = AppUtil.calculateRoomTotalsV2(rooms[j]);

      let total = 0;
      rooms.map(data => (total = parseFloat(total) + parseFloat(data.total)));
      this.setState({
        rooms: rooms,
        total,
      });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.fetchInitData();
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {
    const {property, show, checkin, checkout} = this.props;

    const {rooms, resultMsg} = this.state;
    const total = _.isEmpty(rooms) ? 0 : rooms.reduce((total, product) => parseFloat(total) + parseFloat(product.total), 0);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const formValidation = Yup.object().shape({
      first_name: Yup.string().min(2, 'Too Short!').required('Required'),
      last_name: Yup.string().min(1, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string().matches(phoneRegExp, 'Not valid').min(8, 'to short').max(10, 'to long').required('Required'),
      code: Yup.string().required('Required'),
      recaptcha: Yup.string().required('Required'),
    });

    const initialValues = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      code: property ? property.phone_number_code : '',
      tands: false,
      recaptcha: '',
    };

    if (!show) {
      return <div/>;
    }

    return (
      <Modal
        {...this.props}
        size="xl"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={'d-flex flex-column'}>
            <p className={'mb-0 '}><strong>{property.name} </strong></p>
            <p className={'mb-0 '}><small>Booking date
              : {AppUtil.formatDateTime(checkin, 'datef')} - {AppUtil.formatDateTime(checkout, 'datef')} </small>
            </p>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(data) => this.addBooking(data)}>
          {({errors, touched, setFieldValue, handleSubmit}) => (
            <React.Fragment>
              <Modal.Body>
                <form>
                  <div className={'row'}>
                    <div className={'col-md-4 col-sm-12 mb-3 d-none d-lg-block'}>
                      <div className={'event-cover mb-3'}>
                        {(property.booking_engine_content && property.booking_engine_content.bengine_cover_image && property.booking_engine_content.bengine_cover_image.cover_sm) &&
                         <Img
                           className="card-img-top"
                           src={property.booking_engine_content.bengine_cover_image.cover_sm}
                           loader={<Loading heightMatch={false}/>}
                         />
                        }
                      </div>
                      <h5>{property.name}</h5>
                      <p className={'small text-muted'}>
                        <FontAwesomeIcon icon={faMapMarker} className={'mr-2'}/>
                        {property.full_address}
                      </p>
                      <hr/>
                      <p className={'small'}><strong>Booking Dates</strong></p>
                      <div className={'row'}>
                        <div className={'col d-inline-flex'}>
                          <DateFormatted containerClass={''} date={checkin}/>
                          <div className={'text-center align-self-center'}>
                            <FontAwesomeIcon className={'mx-4'} icon={faArrowRight}/>
                          </div>
                          <DateFormatted containerClass={''} date={checkout}/>
                        </div>
                      </div>
                      <div className={'border rounded p-2 mt-3'}>
                        <p className={'mb-1 green-cl'}>
                          <strong>Go Green</strong>
                        </p>
                        <p className={'small text-muted'}>
                          Your booking details will be emailed to your email address. You can show the same at the time
                          of checkin.
                        </p>
                      </div>
                    </div>
                    <div className={'col-lg-8 col-sm-12 border-left'}>
                      <h6 className={'mb-0'}>
                        <FontAwesomeIcon icon={faDoorOpen} size={'sm'} className={'grey-cl mr-1'}/> Room Details
                      </h6>
                      <p className={'small text-muted'}>Configure guest(s) in each of your selected room(s).</p>
                      {rooms && rooms.length > 0 &&
                       <div className="list-group">
                         {rooms.map((data, i) => (
                           <div className={'list-group-item'} key={i}>
                             <div className={'row'}>
                               <div className={'col-8 col-md-8 col-lg-9 mb-3'}>
                                 <div className={'row'}>
                                   <div className={'col-12 col-lg-3'}>
                                     <p className={'mb-0'}><strong>Room : {(i + 1)}</strong></p>
                                   </div>
                                   <div className={'col-12 col-lg-9'}>
                                     <div className={'row small mb-2'}>
                                       <div className={'col d-inline-flex'}>
                                         <p className={'mb-0 mr-5'}>
                                           <span className={'small text-muted'}>Room Type</span> <br/>
                                           <strong>{data.room_type_name}</strong>
                                         </p>
                                         <p className={'mb-0'}>
                                           <span className={'small text-muted'}>Package</span> <br/>
                                           <strong>{data.package_name}</strong>
                                         </p>
                                       </div>
                                     </div>
                                     <p className={'mb-1 small'}>
                                       <span className={'small text-muted'}>Room Guest</span>
                                     </p>
                                     <div className={'d-inline-flex small text-center'}>
                                       <div className={'mr-2'}>
                                         <div className="btn-group btn-group-sm">
                                           <button
                                             className="btn  border red-cl" type="button"
                                             onClick={() => this.updateIndex(0, i, 'g-')}>
                                             <FontAwesomeIcon icon={faMinus}/>
                                           </button>
                                           <button type="button" className="btn border">
                                             {data.no_of_guest}
                                           </button>
                                           <button
                                             className="btn border green-cl" type="button"
                                             onClick={() => this.updateIndex(2, i, 'g+')}>
                                             <FontAwesomeIcon icon={faPlus}/>
                                           </button>
                                         </div>
                                         <p className={'small mb-0 mt-1 text-muted'}>Adult (
                                           Max {data.occupancy_data.max_occupancy} )</p>
                                       </div>
                                       <div className={'mr-2'}>
                                         <div className="btn-group btn-group-sm" role="group">
                                           <button
                                             className="btn  border red-cl" type="button"
                                             onClick={() => this.updateIndex(0, i, 'c-')}>
                                             <FontAwesomeIcon icon={faMinus}/>
                                           </button>
                                           <button type="button" className="btn border">
                                             {data.no_of_children}
                                           </button>
                                           <button
                                             className="btn border green-cl" type="button"
                                             onClick={() => this.updateIndex(2, i, 'c+')}>
                                             <FontAwesomeIcon icon={faPlus}/>
                                           </button>
                                         </div>
                                         <p className={'small mb-0 mt-1 text-muted'}>Child (
                                           Max {data.occupancy_data.child_occupancy} )</p>
                                       </div>
                                       <div className={'mr-2'}>
                                         <div className="btn-group btn-group-sm" role="group">
                                           <button
                                             type="button" className="btn  border red-cl"
                                             onClick={() => this.updateIndex(0, i, 'i-')}>
                                             <FontAwesomeIcon icon={faMinus}/>
                                           </button>
                                           <button type="button" className="btn border">
                                             {data.no_of_infant}
                                           </button>
                                           <button
                                             type="button" className="btn border green-cl"
                                             onClick={() => this.updateIndex(2, i, 'i+')}>
                                             <FontAwesomeIcon icon={faPlus}/>
                                           </button>
                                         </div>
                                         <p className={'small mb-0 mt-1 text-muted'}>Infant (
                                           Max {data.occupancy_data.infant_occupancy} )</p>
                                       </div>
                                     </div>
                                   </div>
                                 </div>

                               </div>
                               <div className={'col-4 col-md-4 col-lg-3 text-right'}>
                                 <p className={'mb-0'}><strong>{property.currency} {data.total}</strong></p>
                               </div>
                             </div>
                           </div>
                         ))}
                         <div className={'list-group-item'}>
                           <div className={'row'}>
                             <div className={'col-5'}>
                               <p className={'mb-0'}>
                                 <strong>Total</strong>
                               </p>
                             </div>
                             <div className={'col-7 text-right'}>
                               <p className={'mb-0'}><strong>{property.currency} {total.toFixed(2)}</strong></p>
                             </div>
                           </div>
                         </div>
                       </div>
                      }

                      <p className={'mb-0 mt-2 text-muted small'}>
                        Inclusive of taxes, breakdown available in next page.
                      </p>

                      <hr/>

                      <div className={'row'}>
                        <div className={'col-sm-12'}>
                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faUser} size={'sm'} className={'grey-cl mr-1'}/> Name
                          </h6>
                          <p className={'small text-muted'}>Name of the primary contact for the booking.</p>
                          <div className="form-row mb-2">
                            <div className="col-6 form-group">
                              <Input
                                required
                                name='first_name'
                                label={'First Name'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <Input
                                required
                                name='last_name'
                                label={'Last Name'}
                                className={'form-control'}
                              />
                            </div>
                          </div>

                          <h6 className={'mb-0'}>
                            <FontAwesomeIcon icon={faFileContract} size={'sm'} className={'grey-cl mr-1'}/> Contact
                          </h6>
                          <p className={'small text-muted'}>Where would you like to receive the booking and be contacted
                            if required ?</p>
                          <div className="form-row">
                            <div className="col-lg-6 col-md-6 form-group">
                              <Input
                                required
                                name='email'
                                type={'email'}
                                label={'E-mail'}
                                className={'form-control'}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 ">
                              <div className={'row'}>
                                <div className={'col-4 pr-0 form-group'}>
                                  <Select
                                    className={'form-control rounded-right-0'}
                                    name='code'
                                    label={'Country'}
                                    placeholder='Select an Option'
                                    options={SelectList.phoneCountryCode()}
                                  />
                                </div>
                                <div className={'col-8 pl-0 form-group'}>
                                  <Input
                                    required
                                    type={'tel'}
                                    name='phone'
                                    label={'Phone'}
                                    className={'form-control rounded-left-0'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-12 form-group">
                              <Checkbox
                                required
                                className={'mr-2'}
                                name='tands'
                                text='Terms And Conditions'
                              />
                              <p className={'small text-muted'}>
                                I agree to {property.name} Terms of Service & Privacy Policy ( listed below ) and
                                Renzo's
                                <a
                                  rel='noopener noreferrer' target={'_blank'}
                                  href={'https://renzo.in/policies/'}>Terms of service & Privacy Policy</a>.
                              </p>
                              <PolicyLinks property={property}/>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="col-12 form-group">
                              <div className={'form-element input'}>
                                <span className={'label'}>Recaptcha Validation</span>
                                <Reaptcha
                                  sitekey={AppConfig.reCaptchaKey}
                                  onVerify={(data) => setFieldValue('recaptcha', data)}
                                  onExpire={() => setFieldValue('recaptcha', '')}
                                />
                                {errors.recaptcha && touched.recaptcha && (
                                  <span className={'error'}>{errors.recaptcha}</span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_room_booking_rules)) &&
                       <div>
                         <h6 className={'mb-3'}><FontAwesomeIcon icon={faIdBadge} className={'grey-cl mr-1'}/> Booking Rules </h6>
                         <RuleList ruleList={property.booking_engine_content.bengine_room_booking_rules}/>
                       </div>
                      }
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer className={'justify-content-center'}>
                <div className={'row w-100'}>
                  <div className={'col-12 col-md-12 col-lg-8'}>
                    <Alerts
                      status={resultMsg.status}
                      success={resultMsg.success}
                      error={resultMsg.error}
                    />
                  </div>
                  <div className={'col-12 col-md-12 col-lg-4'}>

                    <button onClick={handleSubmit} className={'btn btn-success btn-block btn-lg'}>
                      Place Order <FontAwesomeIcon className={'white-cl mr-2'} icon={faArrowRight}/>
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </React.Fragment>
          )}
        </Formik>
      </Modal>

    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      rooms: [],
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      total: 0.0,
    };
  }
}


/* Export Component ==================================================================== */
export default RoomBooking;
