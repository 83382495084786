/**
 *
 * Property Page Details
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import {Constants} from '../../constants';
// Components
import Error from '../../components/general/Error';
import Loading from '../../components/general/Loading';
import LocationDetailsBox from '../../components/booking-engine/LocationDetailsBox';
import SupportInformation from '../../components/booking-engine/SupportInformation';
// Actions
import * as PropertyActions from '../../redux/property/actions';
import {Img} from 'react-image';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  pageContent: state.property.pageContent,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPage: PropertyActions.setPage,
};


/* Component ==================================================================== */
class PropertyPageDetails extends Component {
  static componentName = 'PropertyPageDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    if (this.props.match.params.pageUrl) {
      this.props.setPage(this.props.match.params.pageUrl, this.props.match.params.propertyId)
        .then(() => {
          this.setState({loading: false});
        })
        .catch(err => {
          const error = AppAPI.handleError(err);
          this.setState({error: error, loading: false});
        });
    } else {
      this.props.history.push(`/p/${this.props.match.params.propertyId}/not-found`);
    }
  };


  alignContent = (pageContent, content) => {
    let className;
    switch (pageContent.layout_type) {
    case Constants.PAGE_LAYOUT.SIDEBAR:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0';
      break;
    case Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0 ';
      break;
    case Constants.PAGE_LAYOUT.NO_SIDEBAR_CENTER_ALIGNED:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0  mx-auto';
      break;
    case Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED:
      className = 'col-lg-8 sm-12 mb-sm-5 mb-md-0 mx-auto';
      break;
    case Constants.PAGE_LAYOUT.FULL_WIDTH:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
      break;
    case Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
      break;
    default:
      className = 'col-lg-12 sm-12 mb-sm-5 mb-md-0';
    }
    return (
      <div className={className}>
        {content}
      </div>
    );
  };


  render = () => {
    const {pageContent, property} = this.props;
    const {loading, error} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const sideBar = (
      <React.Fragment>
        <div className={'mb-3'}>
          <LocationDetailsBox property={property}/>
        </div>
        <div className={'mb-3'}>
          <SupportInformation property={property}/>
        </div>
      </React.Fragment>
    );

    const content = (
      <React.Fragment>
        {(pageContent.layout_type === Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED) ?
          <React.Fragment>
            <p>
              {pageContent.short_content && pageContent.short_content}
            </p>
          </React.Fragment>
          :
          <React.Fragment>
            <h2>{pageContent.title}</h2>
            <hr/>
            <p className={'display-linebreak font-weight-light mb-3'}>
              {pageContent.short_content && pageContent.short_content}
            </p>
            {pageContent.image && pageContent.image.cover &&
             <Img
               className="d-block w-75 mx-auto"
               src={pageContent.image.cover}
               loader={<Loading heightMatch={false}/>}
             />
            }
          </React.Fragment>
        }
        <p className={'display-linebreak font-weight-light mb-3'}>{pageContent.content}</p>
      </React.Fragment>
    );


    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>{pageContent.title} | {property.name}</title>
        </Helmet>

        {(pageContent.layout_type === Constants.PAGE_LAYOUT.FULL_WIDTH_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.NO_SIDEBAR_COVER_IMAGE_CENTER_ALIGNED || pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE) &&
         <div
           className={'property-page-header'}
           style={(pageContent.image && pageContent.image.cover_2x) && {
             backgroundImage: `url(${pageContent.image.cover_2x})`,
             backgroundPosition: 'center',
             backgroundSize: 'cover',
             backgroundRepeat: 'no-repeat',
           }}
         >
           <table className={'property-page-header-inner w-100 h-100'}>
             <tbody>
               <tr>
                 <td className={'text-light'}>
                   <div className={'container'}>
                     {this.alignContent(pageContent, <h1>{pageContent.title}</h1>)}
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
         </div>
        }

        <div className={'container'}>
          <div className={'row mt-4'}>
            {this.alignContent(pageContent, content)}
            {(pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR_COVER_IMAGE || pageContent.layout_type === Constants.PAGE_LAYOUT.SIDEBAR) &&
             <div className={'col-md-4 col-sm-12 mb-5 d-none d-lg-block'}>
               {sideBar}
             </div>
            }

          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      priceTaxBreakDown: false,
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PropertyPageDetails);
