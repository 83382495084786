/**
 *
 * Booking Details
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Strings} from '../../constants';
// Components
import {Alerts} from '../../components/ui';
import Error from '../../components/general/Error';
import OrderListView from '../order/OrderListView';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ClickToView from '../../components/general/ClickToView';
import DateFormatted from '../../components/general/DateFormatted';
import LocationDetailsBox from '../../components/booking-engine/LocationDetailsBox';
import SupportInformation from '../../components/booking-engine/SupportInformation';
import {faBaby, faCheckCircle, faChild, faLongArrowAltRight, faUser} from '@fortawesome/free-solid-svg-icons';
// Actions
import * as BookingActions from '../../redux/booking/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  booking: state.booking.booking,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setBooking: BookingActions.setBooking,
};


/* Component ==================================================================== */
class BookingDetails extends Component {
  static componentName = 'BookingDetails';
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData()
  };

  fetchInitData = () => {
    this.setState({refreshing: true})
    this.props.setBooking(this.props.match.params.hash, this.props.match.params.propertyId)
      .then(() => {
        this.setState({loading: false, refreshing: false});
      })
      .catch(err => {
        const error = AppAPI.handleError(err);
        this.setState({error: error, loading: false, refreshing: false});
      });
  }


  render = () => {
    const {booking, property} = this.props;
    const {loading, error, refreshing} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error full={true} text={error}/>;


    const sideBar = (
      <div>
        <div className={'mb-3'}>
          <LocationDetailsBox property={property}/>
        </div>
        <div className={'mb-3'}>
          <SupportInformation property={property}/>
        </div>
      </div>
    );

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>Booking Confirmation {booking.ref_no} : {property.name}</title>
        </Helmet>


        <div
          className={'booking-header'}
          style={(property.booking_engine_content && property.booking_engine_content.bengine_cover_image && property.booking_engine_content.bengine_cover_image.cover_2x) && {
            backgroundImage: `url(${property.booking_engine_content.bengine_cover_image.cover_2x})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <table className={'booking-header-inner w-100 h-100'}>
            <tbody>
              <tr>
                <td className={'text-light'}>
                  <div className={'container'}>
                    <h1>Booking Confirmation</h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={'container'}>
          <div className={'row mt-4'}>
            <div className={'col-lg-8 sm-12 mb-sm-5'}>

              <Alerts info={Strings.bookingConfirmationText}/>

              <div className={'row'}>
                <div className={'col-12 col-md-5 float-right order-1 order-md-2'}>
                  <p><strong>Booking Information</strong></p>
                  <ul className={'list-inline list-inline-formatted'}>
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Booking Reference</p>
                      <p>{booking.ref_no}</p>
                    </li>
                    {booking.booking_id &&
                     <li className={'list-inline-item'}>
                       <p className={'small text-muted mb-0'}>Booking ID</p>
                       <p>{booking.booking_id}</p>
                     </li>
                    }
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Booking Status</p>
                      <p>
                        <span className={'badge ' + (AppUtil.bookingStatusColor(booking.booking_status))}>
                          {booking.booking_status_display}
                        </span>
                      </p>
                    </li>
                    <li className={'list-inline-item'}>
                      <p className={'small text-muted mb-0'}>Booking Time</p>
                      <p>{AppUtil.formatDateTime(booking.created)}</p>
                    </li>
                  </ul>
                </div>
                <div className={'col-md-7 float-left order-2 order-md-1 border-right'}>
                  <div className={'mb-3'}>
                    <p><strong>Booking Dates</strong></p>
                    <ul className={'list-inline d-inline-flex list-inline-formatted'}>
                      <li className={'list-inline-item d-inline-flex align-items-center'}>
                        <DateFormatted date={booking.checkin} year={true}/>
                        <p className={'ml-3'}>
                          <strong>Checkin</strong> <br/>
                          {AppUtil.formatDateTime(booking.checkin, 'day')}
                        </p>
                      </li>
                      <li className={'list-inline-item d-inline-flex align-items-center'}>
                        <FontAwesomeIcon icon={faLongArrowAltRight}/>
                      </li>
                      <li className={'list-inline-item d-inline-flex align-items-center'}>
                        <DateFormatted date={booking.checkout} year={true}/>
                        <p className={'ml-3'}>
                          <strong>Checkout</strong> <br/>
                          {AppUtil.formatDateTime(booking.checkout, 'day')}
                        </p>
                      </li>
                    </ul>
                  </div>
                  {booking.guest &&
                   <div className={'mb-3'}>
                     <p><strong>Primary Guest</strong></p>
                     <ul className={'list-inline list-inline-formatted'}>
                       {booking.guest.name &&
                        <li className={'list-inline-item'}>
                          <p className={'small text-muted mb-0'}>Name</p>
                          <p>{booking.guest.name}</p>
                        </li>
                       }

                       {booking.guest.phone &&
                        <li className={'list-inline-item'}>
                          <p className={'small text-muted mb-0'}>Phone</p>
                          <p>{booking.guest.phone}</p>
                        </li>
                       }

                       {booking.guest.email &&
                        <li className={'list-inline-item'}>
                          <p className={'small text-muted mb-0'}>Email</p>
                          <p><ClickToView content={booking.guest.email} hiddenText={'Click to See Email'}/></p>
                        </li>
                       }
                     </ul>
                   </div>
                  }
                </div>
              </div>

              {!_.isEmpty(booking.booking_rooms) &&
               <div className={'mb-3'}>
                 <p><strong>Room(s)</strong></p>
                 <ul className={'list-group'}>
                   {booking.booking_rooms.map((data, i) => (
                     <li className={'list-group-item'} key={i}>
                       <div className={'row'}>
                         <div className={'col-12 col-md-6'}>
                           <div className={'row'}>
                             <div className={'col'}>
                               <p className={'small text-muted mb-0'}>Room</p>
                               <p><strong>Room {i + 1}</strong></p>
                             </div>
                             <div className={'col'}>
                               <p className={'small text-muted mb-0'}>Room Type</p>
                               <p>{data.attributes.room_type_name}</p>
                             </div>
                             <div className={'col'}>
                               <p className={'small text-muted mb-0'}>Occupancy</p>
                               <ul className="list-inline mb-1">
                                 <li className="list-inline-item small">
                                   <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2 blue-cl'}/>
                                   {data.no_of_guest}
                                 </li>
                                 {data.no_of_children !== 0 &&
                                  <li className="list-inline-item small">
                                    <FontAwesomeIcon icon={faChild} size={'sm'} className={'mr-2 blue-cl'}/>
                                    {data.no_of_children}
                                  </li>
                                 }
                                 {data.no_of_guest !== 0 &&
                                  <li className="list-inline-item small">
                                    <FontAwesomeIcon icon={faBaby} className={'mr-2 blue-cl'}/>
                                    {data.no_of_guest}
                                  </li>
                                 }
                               </ul>
                             </div>
                           </div>
                         </div>
                         <div className={'col-12 col-md-6'}>
                           <div className={'row'}>
                             {!_.isEmpty(data.attributes.room_type_packages) &&
                              <div className={'col'}>
                                <p className={'small text-muted mb-0'}>Package & Inclusions</p>
                                <ul className={'list-unstyled'}>
                                  {data.attributes.room_type_packages.map((packageData, j) => (
                                    <li key={j} className={'list-unstyled-item'}>
                                      {packageData.room_type_package ?
                                        <div>
                                          <p className={'mb-0'}>{packageData.room_type_package.name}</p>
                                          <ul className={'list-inline'}>
                                            {packageData.room_type_package.inclusion_break_fast &&
                                             <li className={'list-inline-item'}>
                                               <p className={'mb-0 small'}>
                                                 <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                                 Breakfast Inclusive
                                               </p>
                                             </li>
                                            }

                                            {packageData.room_type_package.inclusion_lunch &&
                                             <li className={'list-inline-item'}>
                                               <p className={'mb-0 small'}>
                                                 <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                                 Lunch Inclusive
                                               </p>
                                             </li>
                                            }

                                            {packageData.room_type_package.inclusion_dinner &&
                                             <li className={'list-inline-item'}>
                                               <p className={'mb-0 small'}>
                                                 <FontAwesomeIcon icon={faCheckCircle} className={'green-cl mr-1'}/>
                                                 Dinner Inclusive
                                               </p>
                                             </li>
                                            }
                                          </ul>
                                        </div>
                                        :
                                        <p>N.A</p>
                                      }
                                    </li>
                                  ))}
                                </ul>
                              </div>
                             }
                           </div>
                         </div>
                       </div>
                     </li>
                   ))}
                 </ul>
                 <hr/>
               </div>
              }
              {!_.isEmpty(booking.orders) &&
               <div className={'mb-3'}>
                 <p><strong>Order(s)</strong></p>
                 <ul className={'list-unstyled'}>
                   {booking.orders.map((order, i) => (
                     <li key={i}>
                       <OrderListView refreshing={refreshing} refresh={this.fetchInitData} order={order}/>
                     </li>
                   ))}
                 </ul>
               </div>
              }
            </div>
            <div className={'col-md-4 col-sm-12 mb-5 d-none d-lg-block'}>
              {sideBar}
            </div>
          </div>
        </div>

      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      priceTaxBreakDown: false,
      loading: true,
      error: null,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
