/**
 * Order Item View
 *  View Order Item details
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import {Constants} from '../../constants';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';


/* Component ==================================================================== */

class OrderItemDetailsView extends Component {
  static componentName = 'OrderItemDetailsView';

  static propTypes = {
    order: PropTypes.object,
    orderItem: PropTypes.object,
    onHide: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({priceTaxBreakDown: false});
    }
    return true;
  }

  render = () => {

    const {order, orderItem, show} = this.props;

    if (!show){
      return <React.Fragment/>;
    }

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Item Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <table className={'table small table border'}>
              <tbody>
                <tr>
                  <td className={'w-75 border-top-0'} colSpan={2}>
                    {orderItem.description}
                    {orderItem.additional_description &&
                     <p className={'small text-muted mb-0'}>{orderItem.additional_description}</p>
                    }
                  </td>
                </tr>
                <tr>
                  <td className={'w-75'}>
                    Price
                  </td>
                  <td className={'text-right'}>
                    <strong>{order.currency} {orderItem.price}</strong>
                  </td>
                </tr>
                {orderItem.sub_quantity_type !== Constants.QUANTITY_TYPE.NOS &&
                 <tr>
                   <td className={'w-75'}>
                     {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small> Price
                   </td>
                   <td className={'text-right'}>
                     <strong>{order.currency} {orderItem.sub_quantity_sub_total}</strong>
                   </td>
                 </tr>
                }
                <tr>
                  <td className={'w-75'}>
                    {orderItem.sub_quantity} <small>{orderItem.sub_quantity_type_display}</small>
                    <strong>x</strong> {orderItem.quantity} <small>{orderItem.quantity_type_display}</small>
                  </td>
                  <td className={'text-right'}>
                    <strong>{order.currency} {orderItem.sub_total}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr/>
            <table className={'table small border'}>
              <tbody>
                <tr>
                  <td className={'w-75'}>Sub Total</td>
                  <td className={'text-right'}><strong>{order.currency} {orderItem.sub_total}</strong>
                  </td>
                </tr>
                {parseFloat(orderItem.discount) > 0 && orderItem.discount_before_tax && (
                  <tr>
                    <td className={'w-75'}>Discount</td>
                    <td className={'text-right'}><strong>- {order.currency} {orderItem.discount}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={'w-75'}>
                    Tax
                    {orderItem.tax_data && orderItem.tax_data.description && (
                      <p className={'small text-muted mb-0'}>{orderItem.tax_data.description}</p>
                    )}

                    {orderItem.tax_expanded && orderItem.tax_expanded.tax_data && orderItem.tax_expanded.tax_data.length > 0 &&
                     <React.Fragment>
                       <span
                         className={'a small p-0 m-0 ml-2'}
                         onClick={() => this.setState({priceTaxBreakDown: !this.state.priceTaxBreakDown})}
                       >
                         (Tax Information)
                       </span>
                       <Collapse in={this.state.priceTaxBreakDown}>
                         <table className={'table table-sm text-secondary border mt-2'}>
                           <thead>
                             <tr>
                               <th className={'small text-dark'}>Tax Name</th>
                               <th className={'text-right text-dark small'}>Tax Value</th>
                             </tr>
                           </thead>
                           <tbody>
                             {orderItem.tax_expanded.tax_data.map((data, i) => (
                               <tr key={i}>
                                 <td className={'small'}>{data.name}</td>
                                 <td className={'text-right small'}>{order.currency} {data.value}</td>
                               </tr>
                             ))}
                           </tbody>
                         </table>
                       </Collapse>
                     </React.Fragment>
                    }
                  </td>
                  <td className={'text-right'}><strong>{order.currency} {orderItem.tax}</strong></td>
                </tr>
                {parseFloat(orderItem.discount) > 0 && !orderItem.discount_before_tax && (
                  <tr>
                    <td className={'w-75'}>Discount</td>
                    <td className={'text-right'}><strong>{order.currency} {orderItem.discount}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={'w-75'}><strong>Total</strong></td>
                  <td className={'text-right'}><strong>{order.currency} {orderItem.total}</strong></td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={this.props.onHide}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      priceTaxBreakDown: false,
    };
  }
}

/* Export Component ==================================================================== */
export default OrderItemDetailsView;
