/**
 * App Error Messages
 *
 */

const ERRORS = {
  // Defaults
  default: 'Hmm, an unknown error occurred',
  notfound: 'The link your are trying to access is not valid.',
};

export default ERRORS;