/**
 * Property Actions
 *
 */

import AppAPI from '../../lib/api';

export const setProperty = (propertyData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof propertyData === 'object') {
    dispatch({
      type: 'SET_PROPERTY',
      data: propertyData,
    });
    return resolve(propertyData);
  } else if (propertyData) {
    return AppAPI.bengineapi.get(propertyData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_PROPERTY',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove property
 */
export function unSetProperty() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_PROPERTY',
      data: null
    });

  });
}

/**
 * Update Property
 */
export const updateProperty = (propertyData) => setProperty(propertyData);


/**
 * Set Property Page
 */
export const setPage = (pageData, propertyId = null) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof pageData === 'object') {
    dispatch({
      type: 'SET_PAGE',
      data: pageData,
    });
    return resolve(pageData);
  } else if (pageData && propertyId) {
    return AppAPI.bengineapi.get(propertyId + '/page/'+ pageData + '/')
      .then((res) => {
        dispatch({
          type: 'SET_PAGE',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Remove Page
 */
export function unSetBooking() {
  return (dispatch) => new Promise(async () => {
    return dispatch({
      type: 'SET_BOOKING',
      data: null
    });

  });
}