/**
 * RoomTypeDetails
 *  View for Room Type Details
 *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Moment from 'moment';
// Components
import Helmet from 'react-helmet';
import {Img} from 'react-image';
import Loading from '../../components/general/Loading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBaby,
  faBed,
  faBoxOpen,
  faChild,
  faDoorOpen,
  faQuestion,
  faRulerCombined,
  faSmoking,
  faSmokingBan,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import AvailabilityLookupBox from '../../components/booking-engine/AvailabilityLookupBox';
import SupportInformation from '../../components/booking-engine/SupportInformation';
import RoomTypePackageListing from './RoomTypePackageListing';
import FAQList from '../../components/booking-engine/FAQList';
// Actions
import * as GeneralActions from '../../redux/general/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
  roomType: state.roomType.roomType,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setCaptcha: GeneralActions.setCaptcha,
};

/* Component ==================================================================== */
class RoomTypeDetails extends Component {

  static propTypes = {};

  doLookUp = (checkin, checkout, captcha) => {
    this.props.setCaptcha(captcha)
      .then(() => this.props.history.push('/p/' + this.props.match.params.propertyId + '/room/availability/' + Moment(checkin).format('YYYY-MM-DD') + '/to/' + Moment(checkout).format('YYYY-MM-DD')),
      );
  };

  render = () => {
    const {roomType, property} = this.props;

    const sideBar = (
      <div>
        <div className={'mb-3'}>
          <AvailabilityLookupBox action={this.doLookUp}/>
        </div>
        <div className={'mb-3'}>
          <SupportInformation property={property}/>
        </div>
      </div>
    );

    return (
      <div className={'screen-container'}>
        <Helmet>
          <title>{roomType.name} | {property.name}</title>
          {roomType.short_description && <meta name="description" content={property.short_description}/>}
        </Helmet>
        {roomType.room_type_cover_image && roomType.room_type_cover_image.cover_2x &&
         <div
           className={'room-type-header'}
           style={roomType.room_type_cover_image && roomType.room_type_cover_image.cover_2x && {
             backgroundImage: `url(${roomType.room_type_cover_image.cover_2x})`,
             backgroundPosition: 'center',
             backgroundSize: 'cover',
             backgroundRepeat: 'no-repeat',
           }}
         >
           <div className={'room-type-header-inner'}/>
         </div>
        }
        <div className={'container pt-4'}>
          <nav aria-label="breadcrumb mb-3">
            <ol className="breadcrumb small bg-light">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/`}>Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/p/${this.props.match.params.propertyId}/room/`}>Room</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {roomType.name}
              </li>
            </ol>
          </nav>

          <h1 className={'mb-4'}>{roomType.name}</h1>
          <div className={'row'}>
            <div className={'col-lg-8 sm-12 mb-5'}>
              {!_.isEmpty(roomType.slider_images) &&
               <div id="carouselExampleIndicators" className="carousel slide mb-4" data-ride="carousel">
                 <ol className="carousel-indicators">
                   {roomType.slider_images.map((data, i) => (
                     <li
                       data-target="#carouselExampleIndicators"
                       key={i}
                       data-slide-to={i}
                       className={i === 0 ? 'active' : ''}
                     />
                   ))}
                 </ol>
                 <div className="carousel-inner">
                   {roomType.slider_images.map((data, i) => (
                     <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                       <Img
                         className="d-block w-100"
                         src={data.image.slider_2x}
                         loader={<Loading heightMatch={false}/>}
                       />
                     </div>
                   ))}
                 </div>
                 <a
                   className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                   data-slide="prev">
                   <span className="carousel-control-prev-icon" aria-hidden="true"/>
                   <span className="sr-only">Previous</span>
                 </a>
                 <a
                   className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                   data-slide="next">
                   <span className="carousel-control-next-icon" aria-hidden="true"/>
                   <span className="sr-only">Next</span>
                 </a>
               </div>
              }

              <ul className="list-inline inline-list-spacing small mb-3">
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faUser} size={'sm'} className={'mr-2 blue-cl'}/>
                  {roomType.base_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Guest' : 'Guests'}

                </li>
                {roomType.child_occupancy !== 0 &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faChild} size={'sm'} className={'mr-2 blue-cl'}/>
                   {roomType.child_occupancy} {parseInt(roomType.child_occupancy) === 1 ? 'Child' : 'Children'}
                 </li>
                }
                {roomType.infant_occupancy !== 0 &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faBaby} className={'mr-2 blue-cl'}/>
                   {roomType.infant_occupancy} {parseInt(roomType.infant_occupancy) === 1 ? 'Infant' : 'Infants'}
                 </li>
                }

                {roomType.room_dimension &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faRulerCombined} className={'mr-2 blue-cl'}/>
                   {roomType.room_dimension}
                 </li>
                }

                {roomType.smoking_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon
                     icon={roomType.smoking_type_display.includes('Non') ? faSmokingBan : faSmoking}
                     className={'mr-2 blue-cl'}/> {roomType.smoking_type_display}
                 </li>
                }

                {roomType.bed_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faBed} className={'mr-2 blue-cl'}/> {roomType.bed_type_display}
                 </li>
                }

                {roomType.view_type_display &&
                 <li className="list-inline-item">
                   <FontAwesomeIcon icon={faDoorOpen} className={'mr-2 blue-cl'}/> {roomType.view_type_display}
                 </li>
                }
              </ul>

              <hr/>

              {roomType.short_description &&
               <p className={'display-linebreak font-weight-light mb-3'}>{roomType.short_description}</p>
              }

              <div className={'d-block d-lg-none mb-3'}>
                {sideBar}
              </div>

              {roomType.description &&
               <p className={'font-weight-light display-linebreak'}>{roomType.description}</p>
              }

              {!_.isEmpty(roomType.packages) &&
               <div>
                 <div className={'spacer-10'}/>
                 <hr/>
                 <div className={'spacer-10'}/>
                 <h6 className={'mb-3'}><FontAwesomeIcon icon={faBoxOpen} className={'gray-cl mr-1'}/> Packages</h6>
                 <div className={'mb-3'}>
                   <AvailabilityLookupBox action={this.doLookUp} header={false} singleLine={true}/>
                 </div>
                 <div className="list-group list-group-sm">
                   {roomType.packages.map((data, i) => (
                     <RoomTypePackageListing data={data} key={i}/>
                   ))}
                 </div>

               </div>
              }

              {(property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_faq)) &&
               <div>
                 <div className={'spacer-10'}/>
                 <hr/>
                 <div className={'spacer-10'}/>
                 <h6 className={'mb-3'}><FontAwesomeIcon icon={faQuestion} className={'gray-cl mr-1'}/> Frequently Asked
                   Questions</h6>
                 <div className={'mb-3'}>
                   <FAQList FAQList={property.booking_engine_content.bengine_faq}/>
                 </div>
               </div>
              }
            </div>
            <div className={'col-md-4 col-sm-12 mb-5 d-none d-lg-block'}>
              {sideBar}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeDetails);

