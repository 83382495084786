/**
 * Global App Config
 *
 */

import AppAPI from './api';

let environment = 'production';
let reCaptchaKey  = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
let reCaptchaKeyInvisible  = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  environment = process.env.REACT_APP_ENVIRONMENT;
}

if (process.env.REACT_APP_RE_CAPTCHA_KEY) {
  reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY;
}

if (process.env.REACT_APP_RE_CAPTCHA_KEY_INVISIBLE) {
  reCaptchaKeyInvisible = process.env.REACT_APP_RE_CAPTCHA_KEY_INVISIBLE;
}

const configVariables = {
  // App Details
  appName: 'Renzo Booking Engine',
  APITokenIssuer: 'Renzo Inc API',
  DEV: (environment !== 'production' && environment !== 'staging'),
  environment: environment,
  endpoint: AppAPI.hostname,
  hostname: '//renzo.in',
  version: process.env.REACT_APP_VERSION,
  buildTime: process.env.REACT_APP_BUILD_TIME,
  bookingHostname: process.env.REACT_APP_HOST_NAME,
  reCaptchaKey: reCaptchaKey,
  reCaptchaKeyInvisible: reCaptchaKeyInvisible,
  localStorageKeys: {
    RELEASE_VERSION: 'system/version',
    USER_TOKEN: 'user/token',
  },
};

export default configVariables;