/**
 * Room Type Reducer
 *
 */

// Set initial state
const initialState = {
  roomType: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_ROOM_TYPE':
    let roomType = [];
    if (action.data && typeof action.data === 'object') {
      roomType = action.data;
    }

    return {
      ...state,
      roomType
    };

  default:
    return state;
  }
};

export default reducer;