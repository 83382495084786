/**
 * Header
 */
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';


//Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  property: state.property.property,
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class Header extends Component {
  static componentName = 'Header';

  isUrl = (s) => {
    try {
      new URL(s);
      return true;
    } catch {
      return false;
    }
  };

  render = () => {
    const {property} = this.props;
    return (
      <div className="header border-bottom">
        {property.booking_engine_content && !_.isEmpty(property.booking_engine_content.bengine_header_links) &&
         <div className={'nav-links-container border-top py-0'}>
           <div className={'container'}>
             <nav className="navbar navbar-expand-lg navbar-light py-0">
               <Link className="navbar-brand" to={`/p/${property.property_slug}`}>
                 {(property.logo && property.logo.logo) ?
                   <img width={240} src={property.logo.logo} alt={property.name}/>
                   :
                   <h4>{property.name}</h4>
                 }
               </Link>
               <button
                 className="navbar-toggler" type="button" data-toggle="collapse"
                 data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                 aria-expanded="false"
                 aria-label="Toggle navigation">
                 <span className="navbar-toggler-icon"/> Menu
               </button>

               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                 <ul className="navbar-nav mr-auto">
                   {property.booking_engine_content.bengine_header_links.map((data, i) => (
                     <li key={i} className="nav-item">
                       {this.isUrl(data.link) ? <a className={'nav-link'} href={data.link}>{data.title}</a> :
                         <Link to={`/p/${data.link}`} className={'nav-link mr-2 py-2'}>{data.title}</Link>}
                     </li>
                   ))}
                 </ul>
                 <div className="my-2 my-lg-0">
                   {property.allow_room_booking_engine &&
                    <Link
                      to={`/p/${property.property_slug}/room/availability/`}
                      className="btn btn-primary btn-sm my-2 my-sm-0">
                      Book Now
                    </Link>
                   }
                 </div>
               </div>
             </nav>
           </div>
         </div>
        }
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
