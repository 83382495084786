/**
 *
 * Not Found
 * *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class NotFound extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Its a 404!</title>
          <meta name="description" content="The content you are looking for is not found."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container row">
            <div className={'p-3 mt-3 mb-0 text-center mx-auto'}>
              <div className={'col-md-12 p-lg-2'}>
                <h1><FontAwesomeIcon className={'red-cl'} icon={faTimesCircle} size={'sm'}/>  404 Error !</h1>
                <p className={'lead'}>Hmmm, that page doesn't exist.</p>
                <p>You are seeing this page because the URL you requested does not exist.</p>
                <p>Please <a href={'https://renzo.in/contact'}>report to us</a> if you think this is an error.</p>
                <a className={'btn btn-success'} href={'https://renzo.in'}>Back to Renzo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default NotFound;