import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import * as EventActions from '../../redux/event/actions';

// Components
import Loading from '../../components/general/Loading';


/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  event: state.event.event
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEvent: EventActions.setEvent,
};

export default function decorator(ComposedComponent) {
  class EventRequired extends Component {
    static contextTypes = {
      match: PropTypes.object,
    };

    componentDidMount () {
      const {event} = this.props;
      if (Object.keys(this.props.event).length === 0 || this.props.match.params.eventUrl !== event.event_url) {
        this.props.setEvent(this.props.match.params.eventUrl, this.props.match.params.propertyId)
          .catch(() => {
            this.props.history.push('/p/' + this.props.match.params.propertyId + '/error');
          });
      } else {
        if (!this.props.match.params.propertyId){
          this.props.history.push('/not-found');
        }
      }
    }

    render () {
      const {event} = this.props;
      if (_.isEmpty(event) || this.props.match.params.eventUrl !== event.event_url) return <Loading/>;
      return <ComposedComponent {...this.props} />;
    }

  }

  return connect(mapStateToProps, mapDispatchToProps)(EventRequired);
}