/**
 *
 * Booking Engine Index
 * Small info abt booking engine to users
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Font-Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import logo from '../../images/logo.png';

/* Component ==================================================================== */
class Index extends Component {
  static componentName = 'Index';

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Booking Engine</title>
          <meta
            name="description"
            content="Renzo uses bookings.renzo.in as a part of service to facilitate bookings."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container container row">
            <div className="col-lg-8 col-md-12 col-sm-12 mx-auto">
              <h1>
                <FontAwesomeIcon className={'green-cl'} icon={faBed} size={'sm'}/> Renzo Booking Engine
              </h1>
              <p className={'lead'}>only bookings accepted here :)</p>
              <p>Renzo uses bookings.renzo.in as a part of service to facilitate online bookings.</p>
              <p>Booking engines are creted by our partners to list their inventory online, for any other information go
                to <a href="https://renzo.in">renzo.in</a>.</p>

              <div>
                <div className="text-md-right text-center brand">
                  <img width={120} alt={'Renzo'} src={logo}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}


export default Index;