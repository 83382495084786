/**
 * AvailabilityLookupBox
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Reaptcha from 'reaptcha';
import Moment from 'moment';
import _ from 'lodash';
// Consts and Libs
import AppUtil from '../../lib/util';
import {AppConfig} from '../../constants';
// Components
import Calendar from 'react-calendar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

import 'react-calendar/dist/Calendar.css';
/* Component ==================================================================== */
class AvailabilityLookupBox extends Component {
  static componentName = 'AvailabilityLookupBox';

  static propTypes = {
    action: PropTypes.func,
    selectedCheckinDate: PropTypes.string,
    selectedCheckoutDate: PropTypes.string,
    singleLine: PropTypes.bool,
    header: PropTypes.bool,
  };

  static defaultProps = {
    singleLine: false,
    header: true,
  };


  onSelect = (date) => {
    if (!_.isEmpty(date)) {
      this.setState({
        checkin: Moment(date[0]), checkout: Moment(date[1]), showDatePicker: false, lookupDisabled: false
      });
    }
  };

  action = (captcha) => {
    const {checkin, checkout} = this.state;
    if (this.props.action && !this.state.lookupDisabled) {
      this.setState({lookupDisabled: true, showDatePicker: false});
      this.props.action(checkin, checkout, captcha);
    }
    this.captcha.reset();
  };

  processAction = () => {
    if (this.state.captchaLoad){
      this.captcha.execute();
    }
  };


  render = () => {
    const {singleLine, header} = this.props;
    const {showDatePicker, checkin, checkout, lookupDisabled, captchaLoad} = this.state;
    return (
      <div className={'lookup-box'}>
        {header &&
         <React.Fragment>
           <h5 className={'mb-1'}>Availability</h5>
           <p className={'text-muted small'}>When would you like to stay ?</p>
         </React.Fragment>
        }


        <div className={'border p-3 rounded'}>
          <div className={'row'}>
            <div className={`${singleLine ? 'col-sm-12 col-md-8' : 'col-12'}`}>
              <div className={'row'}>
                <div className={'col-6'}>
                  <div className={'a pb-2 border-bottom'} onClick={() => this.setState({showDatePicker: !showDatePicker})}>
                    <p className={'small text-muted mb-0'}>
                      <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Check-in
                    </p>
                    <p className={'mb-0'}>{AppUtil.formatDateTime(checkin, 'datef')}</p>
                  </div>
                </div>
                <div className={'col-6'}>
                  <div className={'a pb-2 border-bottom'} onClick={() => this.setState({showDatePicker: !showDatePicker})}>
                    <p className={'small text-muted mb-0'}>
                      <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} className={'blue-cl mr-1'}/> Check-out
                    </p>
                    <p className={'mb-0'}>{AppUtil.formatDateTime(checkout, 'datef')}</p>
                  </div>
                </div>
              </div>
              <div
                className={`${showDatePicker ? 'collapse.show' : 'collapse'} position-absolute border rounded w-100`}
                id="datePicker"
              >
                <div className="bg-white">
                  <div className="text-left mt-2">
                    <Calendar
                      className={'mx-auto w-100'}
                      minDate={new Date()}
                      selectRange={true}
                      defaultValue={[checkin.toDate(), checkout.toDate()]}
                      onChange={this.onSelect}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${singleLine ? 'col-sm-12 col-md-4' : 'col-12'} mt-2`}>
              <button
                onClick={() => this.processAction()}
                className={'btn btn-block btn-primary'}
                disabled={lookupDisabled || !captchaLoad}
              >{captchaLoad ? 'Get Availability' : 'Please Wait' }</button>
            </div>
          </div>
        </div>
        <Reaptcha
          sitekey={AppConfig.reCaptchaKeyInvisible}
          ref={e => (this.captcha = e)}
          onVerify={this.action}
          onLoad={()=>this.setState({captchaLoad: true})}
          size={'invisible'}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      captchaLoad: false,
      showDatePicker: false,
      lookupDisabled: false,
      checkin: this.props.selectedCheckinDate ? this.props.selectedCheckinDate : Moment().startOf('day'),
      checkout: this.props.selectedCheckoutDate ? this.props.selectedCheckoutDate :Moment().add(1, 'days'),
    };
  }
}

/* Export Component ==================================================================== */
export default AvailabilityLookupBox;
