/**
 * General reducer
 *
 */

// Set initial state
const initialState = {
  captcha: {},
};

const reducer =(state = initialState, action) => {
  switch (action.type) {
  case 'SET_CAPTCHA':
    let captcha = [];
    if (action.data && typeof action.data === 'string') {
      captcha = action.data;
    }

    return {
      ...state,
      captcha,
    };

  default:
    return state;
  }
};

export default reducer;