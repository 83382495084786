import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Launch from './Home';
import NotFound from '../NotFound';


const Launchoute = () => (
  <div>
    <Switch>
      <Route path="/" exact component={Launch}/>
      <Route exact component={NotFound}/>
    </Switch>
  </div>
);

export default Launchoute;