/**
 * Date Formatted in row order
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import AppUtil from '../../lib/util';
// Components


/* Component ==================================================================== */
class DateFormatted extends Component {
  static componentName = 'DateFormatted';

  static propTypes = {
    date: PropTypes.any,
    dte: PropTypes.bool,
    year: PropTypes.bool,
    month: PropTypes.bool,
    containerClass: PropTypes.string,
  };

  static defaultProps = {
    dte: true,
    month: true,
    year: false,
  };

  render = () => {
    const {date, containerClass, dte, month, year} = this.props;
    return (
      <div className={`p-2 px-3 grey-light-bg rounded text-center date-formatted ${containerClass}`}>
        <div className="text-uppercase">
          {dte && <span className="small">{AppUtil.formatDateTime(date, 'month')}</span>}
          {month &&
           <React.Fragment>
             <br/><strong className="text-lg">{AppUtil.formatDateTime(date, 'dte')}</strong>
           </React.Fragment>
          }
          {year &&
           <React.Fragment>
             <br/><strong className="small">{AppUtil.formatDateTime(date, 'year')}</strong>
           </React.Fragment>
          }
        </div>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default DateFormatted;
