/**
 * Created by anush on 22/06/17.
 *
 * Combine All Reducers
 *
 */

import {combineReducers} from 'redux';
import booking from './booking/reducer';
import event from './event/reducer';
import general from './general/reducer';
import property from './property/reducer';
import roomType from './roomtype/reducer';

// Combine all
const appReducer = combineReducers({
  booking,
  event,
  general,
  property,
  roomType,
});

// Setup root reducer
const rootReducer = (state, action) => {
  const newState = (action.type === 'RESET') ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;

